import React, { useContext } from 'react'
import '../../css/home/myTeam.css';
import personIcon from '../../assets/icon/person.svg';
import { useEffect } from 'react';
import { useState } from 'react';

export const MemberTable = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
    const [childs, setChilds] = useState([]);
    
    useEffect(() => {
      if(contextByLocalStorage.childsFromLeader) {
        setChilds(contextByLocalStorage.childsFromLeader)
      }
    }, [])
    
  return (
    <div className='table-container'>
        <table className="tabla">
            <tr>
                <th className="Titulo-nombre-activity">Nombre del miembro</th>
                <th className="Titulo-ventas-activity">Ventas totales</th>
                <th className="Titulo-ventaspendientes-activity">Ventas pendientes</th>
                <th className="Titulo-total-activity">Ganancias totales</th>
            </tr>
        <tbody>
        {
                childs.map((child) =>
                <tr>
                    <td className="tabla-nombrefoto">
                        <img src={personIcon} alt=""/>
                        <span className="tabla-Nombre">{child.node.user.username}</span>
                    </td>
                    <td className="tabla-totalventas">{child.totalSales}</td>
                    <td className="tabla-ventaspendientes">{child.pendingSales}</td>
                    <td className="tabla-total">{child.totalProfit}</td>                      
                </tr>
            )
            }
        </tbody>
        </table>
    </div>
  )
}
