import React, { useContext, useEffect, useState } from 'react';
import HeaderMyTeam from './HeaderMyTeam';
import Member from './Member';
import StarIcon from "../../../src/assets/icon/star.svg";
import TresPuntos from "../../../src/assets/icon/icon-park_more.svg";
import InfoIcon from '../../../src/assets/icon/info.svg';
import GrupoIcon from "../../../src/assets/icon/Iconly (1).svg";
import '../../css/home/myTeam.css';
import watchIcon from '../../assets/icon/watch.svg';
import calendarIcon from '../../assets/icon/Calendar.svg';
import { MemberTable } from './MemberTable';
import { Context } from '../../App';
import axios from 'axios';
import { API_URL } from '../../constants.ts';

const MyTeam = () => {
    const {context, setContext} = useContext(Context);
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
    const [isLoading, setIsLoading] = useState(false);

    const currentDate = new Date();
    const oneWeekBefore = new Date(currentDate);
    const oneMonthBefore = new Date(currentDate);
    const oneYearBefore = new Date(currentDate);
    oneYearBefore.setFullYear(currentDate.getFullYear() - 1);
    oneWeekBefore.setDate(currentDate.getDate() - 7);
    oneMonthBefore.setMonth(currentDate.getMonth() - 1);;
    
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];
    const formattedOneWeekBefore = oneWeekBefore.toISOString().split('T')[0];
    const formattedOneMonthBefore = oneMonthBefore.toISOString().split('T')[0];
    const formattedOneYearBefore = oneYearBefore.toISOString().split('T')[0];

    const [childs, setChilds] = useState([]);

    useEffect(() => {
        if(contextByLocalStorage) {
            getChildsFromLeader();
        }
    }, []);

    const getChildsFromLeader = async(filterDate = formattedOneWeekBefore) => {
        setIsLoading(true);
        await axios.post(`${API_URL}/api/structure/childs-from-leader`, {
            leaderId: contextByLocalStorage.id,
            startDate: filterDate,
            endDate: formattedCurrentDate
        }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${contextByLocalStorage.token}`
            },
        })
        .then((res) => {
            if(res.status === 200) {
                setContext({
                    ...context,
                    childsFromLeader: res.data,
                    addVendorReload: false,
                    reloadMyTeam: false
                });
                localStorage.setItem('context', JSON.stringify({
                    ...contextByLocalStorage,
                    childsFromLeader: res.data
                }));
                setChilds(res.data);
            }
        })
        .finally(() => setIsLoading(false))
    };

    useEffect(() => {
        if (context.addVendorReload) {
          setContext((prevContext) => ({
            ...prevContext,
            addVendorReload: false
          }));
          getChildsFromLeader();
          return;
        }
      }, [context.addVendorReload]);

      useEffect(() => {
        if (context.reloadMyTeam) {
          setContext((prevContext) => ({
            ...prevContext,
            reloadMyTeam: false
          }));
          getChildsFromLeader();
          return;
        }
      }, [context.reloadMyTeam]);

    return(
        <div className="analytics">
            <div div className="parido">
                <HeaderMyTeam/>
                <div className="div2myteam"> 
                    <div className="Recuadrito1">
                        <div className="titu345">
                            <div className="totalsales">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <circle cx="25" cy="25" r="25" fill="#2A2A2A"/>
                                    <image href={GrupoIcon} x="13" y="12" width="24" height="24" clip-path="url(#circleClip)"/>
                                    </svg>
                                <h1 className="h1titu345">Enumera tu equipo</h1>
                            </div>
                            <div className="partederecha">
                                <img src={TresPuntos} alt=""/>
                            </div> 
                        </div>
                        <div className='myTeam-list-you-team-wrapper'>
                            <img src={InfoIcon} type="infoIcon" className="infoIcon" alt=""/>
                            <span className='info-label'>En estos momentos no se puede visualizar la información</span>
                        </div>                        
                    </div>
                    </div>

                    <div className="div3myteam"> 
                        <div className="Recuadrito2">
                            <div className="titu">
                                <div className="totalsales">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                        <circle cx="25" cy="25" r="25" fill="#2A2A2A"/>
                                        <image href={StarIcon} x="13" y="12" width="24" height="24" clip-path="url(#circleClip)"/>
                                    </svg>
                                    <h1>Top miembros</h1>
                                </div>
                            
                                <div>
                                    <button className="btn-see-more">Ver más</button>
                                </div>
                            </div>
                            <div style={{overflowY: 'auto' ,maxHeight: 300}}>
                            {
                                childs && childs.map((child) => <Member data={child}/>)
                            }
                            </div>
                        </div>
                    </div>

                    <div className="div4myteam"> 
                        <div className="Recuadrito3">
                            <div className="titu35">
                                <div className="totalsales">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                        <circle cx="25" cy="25" r="25" fill="#2A2A2A"/>
                                        <image href={watchIcon} x="13" y="12" width="24" height="24" clip-path="url(#circleClip)"/><defs/>
                                    </svg>
                                    <h1 className="h1titu3">Actividad del equipo</h1>
                                </div>
                                <div className="select-con-imagen">
                                    <span className="imagen-select">
                                        <img src={calendarIcon} alt="Imagen de select" />
                                    </span>
                                    <select onChange={(e) => getChildsFromLeader(e.target.value)}>
                                        <option value={formattedOneWeekBefore}>Weekly</option>
                                        <option value={formattedOneMonthBefore}>Monthly</option>
                                        <option value={formattedOneYearBefore}>Year</option>
                                    </select>
                                </div>
                            </div>
                            {contextByLocalStorage && <MemberTable/>}
                        </div>
                    </div>
                </div>
            </div> 
    )
}
export default MyTeam;