import React, { useEffect, useState } from 'react'
import SideBar from '../home/SideBar'
import clockIcon from '../../assets/icon/TimeCircle2.svg';
import checkSquareIcon from '../../assets/icon/square-check.svg';
import { Loading } from '../Loading/Loading';
import axios from 'axios';
import { API_URL } from '../../constants.ts';

export const HomeSales = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));

    const [data, setData] = useState([]);
    const [pendingSales, setPendingSales] = useState([]);
    const [finishedSales, setFinishedSales] = useState([]);
    const [sales, setSales] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [showErrorLabel, setShowErrorLabel] = useState(false);

    const getDate = (date = '2024-01-10T03:00:00.000+00:00') => {
        const dateSplit = date.split('T');
        return dateSplit[0];
    }

    const getFinishedSales = async () => {
        await axios.get(API_URL + `/sale/get-by-status-and-validator/${contextByLocalStorage.id}/FINISHED?isOld=false`, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                "ngrok-skip-browser-warning":"any"
              }
        })
        .then((res) => {
            if(res.status === 200) {
                setFinishedSales(res.data);
            }
        })
        .catch((err) => console.log(err))
    };

    const getPendingSales = async () => {
        await axios.get(API_URL + `/sale/get-by-status-and-validator/${contextByLocalStorage.id}/PENDING?isOld=false`, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                "ngrok-skip-browser-warning":"any"
              }
        })
        .then((res) => {
            if(res.status === 200) {
                setPendingSales(res.data);
            }
        })
        .catch((err) => console.log(err))
    };

    useEffect(() => {
        getFinishedSales();
        getPendingSales();
        setData([...pendingSales, ...finishedSales])
    }, []);

  return (
    <div className='padre'>
        <SideBar/>
        <div className='validate-sales-container'>
            <div className='validate-sales-header-container'>
                <h1 className='sales-title'>Bienvenido, {contextByLocalStorage.username}</h1>
                <p className='sales-subtitle'>Aquí tienes el número de ventas que hay que validar:</p>
            </div>

            <div className='sales-box-container'>
                <div className='sales-to-validate-box'>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', margin: 10}}>
                        <img className='clock-img' alt='icon time image' src={clockIcon} />
                        <p className='validate-sales-box-subtitle'>Ventas a Validar</p>
                    </div>
                    <div style={{margin: 20}}>
                        <p className='total-sales-number'>{pendingSales.length}</p>
                    </div>
                </div>
                <div className='sales-validated-box'>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', margin: 10}}>
                        <img className='check-img' alt='icon time image' src={checkSquareIcon} />
                        <p className='validate-sales-box-subtitle'>Ventas Validadas</p>
                    </div>
                    <div style={{margin: 20}}>
                        <p className='total-sales-number'>{finishedSales.length}</p>
                    </div>
                </div>
            </div>
            <div className='validate-sales-table-container' style={{overflowY: 'auto', maxHeight: '790px', width: '1080px'}}>
                <table style={{borderCollapse: 'collapse', marginTop: 20, marginLeft: 20}}>
                        <thead>
                            {
                                data.length > 0 && (
                                <tr>
                                    <th className='validate-sales-th'>Vendedor</th>
                                    <th className='validate-sales-th'>Paquete</th>
                                    <th className='validate-sales-th'>Precio</th>
                                    <th className='validate-sales-th'>Método de Pago</th>
                                    <th className='validate-sales-th'>Fecha</th>
                                    <th className='validate-sales-th'>MTC</th>
                                    <th className='validate-sales-th'>Nombre</th>
                                    <th className='validate-sales-th'>Nota</th>
                                    <th className='validate-sales-th'>Ticket</th>
                                    <th className='validate-sales-th'>Status</th>
                                </tr>
                                )
                            }
                        </thead>
                        <div style={{display: 'flex', justifyContent: 'center', width: '1080px', position:'absolute'}}>
                            {isLoading && <Loading/>}
                        </div>
                        <tbody>
                            {
                                data.length === 0 && (
                                    <div className='no-results-container' style={{width: '1080px'}}>
                                        <p className='no-results-msg'>No hay campañas que mostrar.</p>
                                    </div>
                                )
                            }
                            {
                                data.length > 0 && data.map((user) => <tr key={user.id}>
                                <td className='validate-sales-td'>{user.seller.username}</td>
                                <td className='validate-sales-td'>{user.pack}</td>
                                <td className='validate-sales-td'>${user.price}</td>
                                <td className='validate-sales-td'>{user.paymentMethod}</td>
                                <td className='validate-sales-td'>{getDate(user.date)}</td>
                                <td className='validate-sales-td'>{user.mtc ? user.mtc : '-'}</td>
                                <td className='validate-sales-td'>{user.clientName}</td>
                                <td className='validate-sales-td'>{user.note ? user.note : '-'}</td>
                                <td className='validate-sales-td'>{'???'}</td>
                                <td className='validate-sales-td'>{user.status}</td>
                            </tr>)
                            }
                        </tbody>
                    </table>
                    {showErrorLabel && <p className='error-label' style={{textAlign: 'center'}}>Debe seleccionar al menos una venta para continuar.</p>}
                </div>
        </div>
    </div>
  )
}
