import React, { createContext, useEffect, useState } from 'react';
import Login from './pages/Login';
import Register from './pages/Register';
import HomeDashboard from './pages/HomeDashboard';
import HomeMyTeam from './pages/HomeMyTeam';
import HomeLeaderboard from './pages/HomeLeadboard';
import { BrowserRouter as Router, Route, Routes, redirect } from 'react-router-dom';
import { UploadSales } from './components/sales/UploadSales';
import { CreateCampaign } from './components/campaigns/CreateCampaign';
import { ValidateSales } from './components/sales/ValidateSales';
import { NewCampaigns } from './components/campaigns/NewCampaigns';
import { HomeSales } from './components/sales/HomeSales';
import { HomeCampaign } from './components/campaigns/HomeCampaign';
import { PaymentMethods } from './components/paymentMethods/PaymentMethods';
import { MyProfile } from './components/myProfile/MyProfile';
import { DeleteUser } from './components/delete-user/DeleteUser';
import axios from 'axios';
import { API_URL } from './constants.ts';

export const Context = createContext();
function App() {
  const [context, setContext] = useState({
    token: null,
    id: null,
    username: null,
    role: null,
    team: null,
    date: null,
    igAccounts: [],
    expTime: null,
    teamId: null,
    childsFromLeader: null
  });
  const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
  const {role} = contextByLocalStorage || 'unauthorized';
  
  useEffect(() => {
    if(window.location.pathname !== '/') {
      if(role === 'unauthorized' || role === undefined) {
        let actualUrl = window.location.href;
        let redirectPath = "/";
        let newUrl = actualUrl.replace(/\/[^\/]*$/, redirectPath);
        window.location.href = newUrl;
      }else {
        axios.get(`${API_URL}/user/get/${contextByLocalStorage.id}`, {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${contextByLocalStorage.token}`,
          },
      })
      .catch((err) => {
        let actualUrl = window.location.href;
        let redirectPath = "/";
        let newUrl = actualUrl.replace(/\/[^\/]*$/, redirectPath);
        window.location.href = newUrl;
      })
      }
    }
  }, []);

  return (
    <Context.Provider value={{ context, setContext }}>
      <Router>
        <div className="App">
            <Routes>
              <Route path="/" element={<Login/>} />
            </Routes>
          <div className="padre">
            {
              (role !== 'unauthorized' && role !== undefined) && (
              <Routes>
                <Route path="/home" element={(role === 'ADMIN' || role === 'LEADER' || role === 'EMPLOYEE') ? <HomeDashboard /> : <MyProfile/>} />
                <Route path="/sales" element={(role === 'LEADER' || role === 'EMPLOYEE') ? <UploadSales /> : <MyProfile/>} />
                <Route path="/sales/home" element={role === 'VALIDATOR' ? <HomeSales /> : <MyProfile/>} />
                <Route path="/sales/validate" element={role === 'VALIDATOR' ? <ValidateSales /> : <MyProfile/>} />
                <Route path="/campaign" element={(role === 'LEADER' || role === 'EMPLOYEE') ? <CreateCampaign /> : <MyProfile/>} />
                <Route path="/campaign/home" element={role === 'SPECIAL_USER' ? <HomeCampaign /> : <MyProfile/>} />
                <Route path="/campaign/new" element={role === 'SPECIAL_USER' ? <NewCampaigns /> : <MyProfile/>} />
                <Route path="/register" element={role === 'ADMIN' ? <Register /> : <MyProfile/>} />
                {/* <Route path="/analytics" element={<HomeAnalytics />} /> */}
                <Route path="/myteam" element={(role === 'ADMIN' || role === 'LEADER' ? <HomeMyTeam /> : <MyProfile/>)} />
                <Route path="/leaderboard" element={(role === 'ADMIN' || role === 'LEADER' ? <HomeLeaderboard /> : <MyProfile/>)} />
                <Route path="/paymentMethods" element={role === 'ADMIN' ? <PaymentMethods /> : <MyProfile/>} />
                <Route path="/profile" element={<MyProfile />} />
                <Route path="/user/delete" element={(role === 'ADMIN' || role === 'LEADER' ? <DeleteUser /> : <MyProfile/>)} />
              </Routes>
              )
            }
          </div>
        </div>
      </Router>
    </Context.Provider>
  );
}
export default App;