import RegisterCard  from '../components/register/RegisterCard';
import SideBar from '../components/home/SideBar';

const Login = () => {
    return (
    <div className="padre">
        <SideBar />
        <RegisterCard />
    </div>
    );
}
export default Login;