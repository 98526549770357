import React, { useContext, useState } from 'react'
import PersonaLogo from "../../../src/assets/icon/persona.svg";
import CandadoLogo from "../../../src/assets/icon/candado.svg";
import OjoLogo from "../../../src/assets/icon/ojo.svg";
import LogoLoginMobile from "../../../src/assets/images/Asset 1_4x 1 (1).jpg";
import { useNavigate  } from 'react-router-dom';
import { API_URL } from '../../constants.ts';
import { Loading } from '../Loading/Loading';
import axios from 'axios';
import { Context } from '../../App.jsx';
import { jwtDecode } from 'jwt-decode';

const LoginCard = () => {
  const navigate = useNavigate();
  const {context, setContext} = useContext(Context);

  const [showPassword, setShowPassword] = useState(false);
  const [loginData, setLoginData] = useState({
    username: '',
    password: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleSubmitForm = async () => {
    setShowError(false);
    setIsLoading(true);

    await axios.post(`${API_URL}/auth/login`, loginData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip, deflate, br'
      },
    })
    .then((res) => {
      if(res.status === 200) {
        setShowError(false);
        const {id, sub, role, team, birthdate, igAccounts, exp} = jwtDecode(res.data.token);
        setContext({
          ...context,
          token: res.data.token,
          id,
          username: sub,
          role: role,
          team: team,
          date: new Date(birthdate),
          igAccounts: igAccounts,
          expTime: exp
        });
        localStorage.setItem('context', JSON.stringify({
          token: res.data.token,
          id,
          username: sub,
          role: role,
          team: team,
          date: new Date(birthdate),
          igAccounts: igAccounts,
          expTime: exp
        }));
        if(role === 'ADMIN' || role === 'LEADER') {
          navigate('/home', { replace: true });
        }else {
          navigate('/profile', { replace: true });
        }
      }
    })
    .catch((err) => {
      if(err.response.status === 403) {
        setShowError(true);
      }
    })
    .finally(() => setIsLoading(false));
  };

  return (
    <div className="div2"> 
        <div className="login">
          {
            isLoading && <Loading/>
          }
          <img src={LogoLoginMobile} alt="" className="imagenlogin"/>
          <h1 className="titulo">Login</h1>
          <div className="username">
            <img src={PersonaLogo} type="iconPersona" className="icon" alt="" />
            <input className="usernameInput" type="username" name="username" placeholder="Usuario"
              disabled={isLoading}
              onChange={(e) => setLoginData((prev) => ({
                ...prev,
                username: e.target.value
              })
            )}
          />
          </div>
          <div className="password">
            <img src={CandadoLogo} type="iconCandado" className="icon1" alt="" /> 
            <input className="passwordInput" type={showPassword ? 'text' : 'password'} name="password" placeholder="Contraseña"
              disabled={isLoading}
              onChange={(e) => setLoginData((prev) => ({
                ...prev,
                password: e.target.value
              })
          )}
            /> 
            <img src={OjoLogo} type="iconOjo" className="icon2" alt=""  onClick={() => setShowPassword(!showPassword)}/>
            <div style={{ padding: 10 }}>
              <p className='error_label'>{showError && 'Credenciales inválidas'}</p>
            </div>
          </div>
          <button className="continue-button" onClick={handleSubmitForm} disabled={isLoading}>Continuar</button>
        </div>
    </div>
  )
}
export default LoginCard;