import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import SideBar from '../home/SideBar'
import menuIcon from '../../assets/icon/gold-menu.svg';
import axios from 'axios';
import { API_URL } from '../../constants.ts';
import { Loading } from '../Loading/Loading.jsx';

Modal.setAppElement('#root');

export const NewCampaigns = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));

    const [data, setData] = useState([]);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalRejectIsOpen, setModalRejectIsOpen] = useState(false);

    const openLinkModal = () => {
        setModalIsOpen(true);
    };

    const openRejectModal = () => {
        setModalRejectIsOpen(true);
    };

    const closeAddVendor = () => {
        setModalIsOpen(false);
    };

    const closeRejectModal = () => {
        setModalRejectIsOpen(false);
    };

    const getCampaignValidator = async () => {
        await axios.get(API_URL + `/campaign/get-by-status-and-campaign-validator/${contextByLocalStorage.id}/PENDING?isOld=true`, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                "ngrok-skip-browser-warning":"any"
              }
        })
        .then((res) => {
            if(res.status === 200) {
                setData(res.data);
            }
        })
        .catch((err) => console.log(err))
    };

    const getDate = (date = '2024-01-10T03:00:00.000+00:00') => {
        const dateSplit = date.split('T');
        return dateSplit[0];
    }

    useEffect(() => {
      if(contextByLocalStorage.token) {
        getCampaignValidator();
      }
    }, []);

    const [campaign, setCampaign] = useState([]);
    const addOrRemoveCampaign = (campaignId) => {
        if(!campaign.includes(campaignId)) {
            const s = [...campaign];
            s.push(campaignId);
            setCampaign(s);
        }else {
            const s = campaign.filter(camp => camp !== campaignId);
            setCampaign(s);
        }
    }

    const [campaignToReject, setCampaignToReject] = useState(null);
    const addOrRemoveCampaignToReject = async (campaignId) => {
        openRejectModal();
        setCampaignToReject(campaignId);
    }

    const handleReject = async () => {
        setIsLoading(true);
        await axios.put(`${API_URL}/sale/reject-sale/${campaignToReject}?rejectReason=null`,null ,{
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br'
            },
        })
        .then((res) => {
            if(res.status === 200) {
                if(contextByLocalStorage.token) {
                    getCampaignValidator();
                  }
            }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    };

    const [isLoading, setIsLoading] = useState(false);
    const [showErrorLabel, setShowErrorLabel] = useState(false);

    const handleNewCampaigns = async() => {
        setShowErrorLabel(false);
        if(campaign.length === 0){
            setShowErrorLabel(true);
            return;
        }
        setIsLoading(true);
        await axios.put(`${API_URL}/campaign/finish-campaigns`, campaign, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br'
            },
        })
        .then((res) => {
            if(res.status === 200) {
                if(contextByLocalStorage.token) {
                    getCampaignValidator();
                  }
            }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }

  return (
    <div className='padre'>
        <SideBar/>
        <div className='validate-sales-container'>
            <div className='validate-sales-header-container'>
                <h1 className='sales-title'>Nuevas Campañas</h1>
                <p className='sales-subtitle'>Acá están los detalles de tus campañas</p>
            </div>
            <div className='validate-sales-table-container' style={{overflowY: 'auto', maxHeight: '790px'}}>
                <div style={{margin: 20}}>
                    <div>
                        <div className='table-header-container'>
                            <img src={menuIcon} className="icon shop-fill" alt="" />
                            <p className='sales-title-table cp'>Nuevas Campañas</p>
                        </div>
                    </div>
                    <div>
                    <table style={{borderCollapse: 'collapse', marginTop: 20}}>
                        <thead>
                            <tr>
                                <th className='validate-sales-th'>Cliente</th>
                                <th className='validate-sales-th'>Paquete</th>
                                <th className='validate-sales-th'>Plataforma</th>
                                <th className='validate-sales-th'>Fecha</th>
                                <th className='validate-sales-th'>Nota</th>
                                <th className='validate-sales-th'>Link(s)</th>
                                <th className='validate-sales-th'>Confirmar</th>
                            </tr>
                        </thead>
                        <div style={{display: 'flex', justifyContent: 'center', width: '75vw', position:'absolute'}}>
                            {isLoading && <Loading/>}
                        </div>
                        <tbody>
                            {
                                data.length === 0 && (
                                    <div className='no-results-container'>
                                        <p className='no-results-msg'>No hay campañas que mostrar.</p>
                                    </div>
                                )
                            }
                            {
                                data.length > 0 && data.map((camp) => <>
                                <tr key={camp.id}>
                                <td className='validate-sales-td'>{camp.owner.username}</td>
                                <td className='validate-sales-td'>{camp.sale.pack}</td>
                                <td className='validate-sales-td'>${camp.sale.platform}</td>
                                <td className='validate-sales-td'>{getDate(camp.sale.date)}</td>
                                <td className='validate-sales-td'>{camp.note}</td>
                                <td className='validate-sales-td mtc' onClick={openLinkModal}>MTC</td>
                                <td className='validate-sales-td'>
                                    <div>
                                        <button 
                                            style={{ marginRight: 10 }}
                                            className='btn-table-sales'
                                            onClick={() => addOrRemoveCampaignToReject(camp.id)}>❌
                                        </button>
                                        <button 
                                            style={{ background: campaign.includes(camp.id) && '#E7CC97', color: campaign.includes(camp.id) && 'black' }}
                                            className='btn-table-sales'
                                            onClick={() => addOrRemoveCampaign(camp.id)}>✓
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeAddVendor}
                                contentLabel="Agregar Proveedor Modal"
                                className="modal-content"
                                shouldCloseOnOverlayClick={false}
                                overlayClassName="modal-overlay"
                                style={{
                                    overlay: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo con opacidad
                                    zIndex: 1000,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    },
                                    content: {
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: '#1E1E1E',
                                    width: '400px',
                                    height: '400px',
                                    margin: 'auto',
                                    zIndex: 1001,
                                    borderRadius: 16
                                    },
                                }}
                            >
                            <div className='modal-container-campaigns'>
                                <div className='container-header-modal'>
                                    <h2 className='links-label-title'>Link(s)</h2>
                                    <button className='btn-close-modal-ca' onClick={closeAddVendor}>Cerrar</button>
                                </div>
                                {camp.links.map((link) => <p className='link-list'>{link}</p>)}
                            </div>
                    </Modal>
                    <Modal
                                isOpen={modalRejectIsOpen}
                                onRequestClose={closeRejectModal}
                                contentLabel="Agregar Proveedor Modal"
                                className="modal-content"
                                shouldCloseOnOverlayClick={false}
                                overlayClassName="modal-overlay"
                                style={{
                                    overlay: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo con opacidad
                                    zIndex: 1000,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    },
                                    content: {
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: '#1E1E1E',
                                    width: '600px',
                                    height: '200px',
                                    margin: 'auto',
                                    zIndex: 1001,
                                    borderRadius: 16
                                    },
                                }}
                            >
                            <div className='modal-container-campaigns'>
                                <div className='container-header-modal'>
                                    <h2 className='links-label-title' style={{fontSize: 'inherit'}}>Rechazar campaña?</h2>
                                    <button className='btn-close-modal-ca' onClick={closeRejectModal}>Cerrar</button>
                                </div>
                                <button style={{cursor: 'pointer'}} onClick={() => handleReject()}>Confirmar</button>
                            </div>
                    </Modal>
                                </>)
                            }
                        </tbody>
                    </table>
                    {showErrorLabel && <p className='error-label' style={{textAlign: 'center'}}>Debe seleccionar al menos una campaña para continuar.</p>}
                    <div className='btn-container-table-sales'>
                        {data.length > 0 && <button onClick={() =>handleNewCampaigns()} className='confirm-sales-btn'>Continuar</button>}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
