import React from 'react';
import { LoadingContainer, Container, Top, BottomLeft, BottomRight } from './loadingElements';
import TopImage from '../../assets/top.png';
import BottomLeftImage from '../../assets/bottom-left.png';
import BottomRightImage from '../../assets/bottom-right.png';

export const Loading = () => {
  return (
    <LoadingContainer>
        <Top src={TopImage} $animationDelay="0s" />
            <Container>
                <BottomLeft src={BottomLeftImage} $animationDelay="1s" />
                <BottomRight src={BottomRightImage} $animationDelay="2s" />
            </Container>
    </LoadingContainer>
  )
}
