import React from 'react';
import "../../../src/css/home/home.css";
import "../../css/leaderboard/leaderboard.css";

const HeaderLeaderboard= () => {
    return(
        <div className="leaderboard-header-container">
            <h1 className="leader-board-title-header">Tabla de Posiciones</h1>
            <p className="leader-board-subtitle-header">Aqui esta la tabla de posiciones</p>
        </div>
    )
}
export default HeaderLeaderboard;