import React, { useState } from 'react'
import SideBar from '../home/SideBar'
import '../../css/campaign/campaign.css';
import axios from 'axios';
import { API_URL } from '../../constants.ts';

export const CreateCampaign = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
    const [formData, setFormData] = useState({
        link: '',
        note: ''
    })
    const [links, setLinks] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessLabel, setShowSuccessLabel] = useState(false);
    const [showErrorLabel, setShowErrorLabel] = useState(false);

    const handleCreateCampaign = async() => {
        setShowErrorLabel(false);
        if(links.length === 0) {
            setShowErrorLabel(true);
            return;
        }
        setIsLoading(true);
        await axios.post(`${API_URL}/campaign/create`, {
            links,
            note: formData.note,
            createdBy: contextByLocalStorage.id
        }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${contextByLocalStorage.token}`,
              'Accept-Encoding': 'gzip, deflate, br'
            },
          })
          .then((res) => {
            console.log(res);
            if(res.status === 200) {
                setFormData({
                    link:'',
                    note: ''
                });
                setLinks([]);
                setShowSuccessLabel(true);
                setTimeout(() => {
                    setShowSuccessLabel(false);
                }, 3000);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setIsLoading(false))
    };
  
    return (
    <div className='padre'>
        <SideBar/>
        <div className='campaign-container'>
            <div className='campaign-form'>
                <div style={{marginTop: 40, marginBottom: 40}} className='input-div-campaign'>
                    <h1 className='campaign-title'>Cargar Campaña</h1>
                </div>
                <div className='input-div-campaign'>
                    <p>Link(s)</p>
                    <div className='links-input-container'>
                        <input value={formData.link} onChange={(e) => setFormData((prev) => ({
                            ...prev,
                            link: e.target.value
                        }))} placeholder='Agregá tu enlace' className='links-input' type="text" />
                        <button className="custom-button" onClick={() => {
                            if(formData.link.length > 0) {
                                const linksArray = [...links];
                                linksArray.push(formData.link);
                                setLinks(linksArray);
                                setFormData((prev) => ({
                                    ...prev,
                                    link: ''
                                }))
                            }
                        }}>+</button>
                    </div>
                    {
                        links && links.map((link) => <li key={link} className='link-list-li'>
                        <span className='link-list-label'>{link}</span>
                        <button className='remove-link-btn' onClick={() => {
                            const prevArrayLinks = [...links];
                            let elementToDelete = prevArrayLinks.indexOf(link);
                            if (elementToDelete !== -1) {
                                prevArrayLinks.splice(elementToDelete, 1);
                            }
                            setLinks(prevArrayLinks);
                        }}>x</button>
                    </li>)
                    }
                </div>
                <div className='input-div-campaign'>
                    <p>Nota</p>
                    <textarea cols="30" rows="10" onChange={(e) => setFormData((prev) => ({
                        ...prev,
                        note: e.target.value
                    }))}></textarea>
                </div>
                <div>
                    <button className='btn-campaign' onClick={() => handleCreateCampaign()}>Continuar</button>
                </div>
                <div>
                    {showSuccessLabel && <p className='success-label s'>Venta cargada con éxito</p>}
                    {
                    showErrorLabel && <p className='error-label e'>Completar los campos obligatorios.</p>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
