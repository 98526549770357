import React from 'react'
import { useLocation } from 'react-router-dom';
import LogoSidebar from "../../../src/assets/images/Frame 538.png";
import LogoAdmin from "../../../src/assets/icon/Ellipse 3.svg";
import LogoDashboard from "../../../src/assets/icon/Category.svg";
import LogoMyTeam from "../../../src/assets/icon/3 User.svg";
import LogoAnalytics from "../../../src/assets/icon/Graph.svg";
import LogoLeaderboard from "../../../src/assets/icon/Activity.svg";
import LogoRegisterUser from "../../../src/assets/icon/Add User.svg";
import salesIcon from "../../../src/assets/icon/shop.svg";
import houseIcon from "../../../src/assets/icon/house-fill.svg";
import menuIcon from "../../../src/assets/icon/menu_campaign.svg";
import LogoDeleteUser from "../../../src/assets/icon/Add User (1).svg";
import "../../../src/css/home/home.css";
import { Link } from 'react-router-dom';
import LogoLoginMobile from "../../../src/assets/images/Asset 1_4x 1 (1).jpg";
import LogoMenuMobile from "../../../src/assets/icon/Menu.svg";
import paymentIcon from "../../../src/assets/icon/payment.svg";
import profileIcon from "../../../src/assets/icon/profile.svg";
import exitIcon from "../../../src/assets/icon/exit.svg";
import { useState } from 'react';

const SideBar = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
    const location = useLocation();

    const getLabelByRole = () => {
        const roles = {
            ADMIN: 'Administrador',
            LEADER: 'Lider',
            SUB_LEADER: 'Sublider',
            EMPLOYEE: 'Vendedor',
            SPECIAL_USER: 'Usuario Especial',
            VALIDATOR: 'Validador'
        };
        return roles[contextByLocalStorage.role] || 'Usuario';
    }

    const [responsiveMenu, setResponsiveMenu] = useState(false);

    return (
        <div className={!responsiveMenu ? 'panel' : 'panel-res'}>
            <img onClick={() => setResponsiveMenu(!responsiveMenu)} src={LogoMenuMobile} alt="" className={!responsiveMenu ? 'imagenmenu' : 'imagenmenu-res'}/>
            <img src={LogoLoginMobile} alt="" className={!responsiveMenu ? 'imagenhome' : 'imagenHome-res'}/>
            <div className={!responsiveMenu ? 'panel2' : 'panel2-res'}>
                <div className="imagenpanel">
                    <img src={LogoSidebar} alt=""/>
                </div>
                <div className="botonperfil">
                    <img src={LogoAdmin} alt=""/>
                    {
                        contextByLocalStorage.role && <h1>{getLabelByRole()}</h1>
                    }
                </div>

                <div className="divcon2opc">
                    <div className="titulinho">
                        <h1>General</h1>
                    </div>
                    {
                        (contextByLocalStorage.role === 'ADMIN' || contextByLocalStorage.role === 'LEADER' || contextByLocalStorage.role === 'EMPLOYEE' ) && (
                        <Link to="/home" className='botones-sidebar'>
                            <div className={`lista ${location.pathname === '/home' ? 'listaactive' : ''}`} >
                                <img src={LogoDashboard} alt=""/>
                                <h1>Panel de Estadísticas</h1>
                            </div>
                        </Link>
                        )
                    }
                    {
                        (contextByLocalStorage.role === 'ADMIN' || contextByLocalStorage.role === 'LEADER') && (
                            <Link to="/myteam" className='botones-sidebar'>
                                <div className={`lista ${location.pathname === '/myteam' ? 'listaactive' : ''}`}>
                                    <img src={LogoMyTeam} alt=""/>
                                    <h1>Mi Equipo</h1>
                                </div>
                            </Link>
                        )

                    }
                    {
                        (contextByLocalStorage.role === 'LEADER' || contextByLocalStorage.role === 'EMPLOYEE') && (
                            <Link to="/sales" className='botones-sidebar'>
                                <div className={`lista ${location.pathname === '/sales' ? 'listaactive' : ''}`}>
                                    <img src={salesIcon} alt=""/>
                                    <h1>Cargar Ventas</h1>
                                </div>
                            </Link>
                        )
                    }
                    {
                        (contextByLocalStorage.role === 'VALIDATOR') && (
                            <Link to="/sales/home" className='botones-sidebar'>
                                <div className={`lista ${location.pathname === '/sales/home' ? 'listaactive' : ''}`}>
                                    <img src={houseIcon} alt=""/>
                                    <h1>Inicio Ventas</h1>
                                </div>
                            </Link>
                        )
                    }
                    {
                        (contextByLocalStorage.role === 'VALIDATOR') && (
                            <Link to="/sales/validate" className='botones-sidebar'>
                                <div className={`lista ${location.pathname === '/sales/validate' ? 'listaactive' : ''}`}>
                                    <img src={salesIcon} alt=""/>
                                    <h1>Validar Ventas</h1>
                                </div>
                            </Link>
                        )
                    }
                    {
                        (contextByLocalStorage.role === 'LEADER' || contextByLocalStorage.role === 'EMPLOYEE') && (
                            <Link to="/campaign" className='botones-sidebar'>
                                <div className={`lista ${location.pathname === '/campaign' ? 'listaactive' : ''}`}>
                                    <img src={menuIcon} alt=""/>
                                    <h1>Cargar Campaña</h1>
                                </div>
                            </Link>
                        )
                    }
                    {
                        (contextByLocalStorage.role === 'SPECIAL_USER') && (
                            <Link to="/campaign/home" className='botones-sidebar'>
                                <div className={`lista ${location.pathname === '/campaign/home' ? 'listaactive' : ''}`}>
                                    <img src={houseIcon} alt=""/>
                                    <h1>Inicio Campañas</h1>
                                </div>
                            </Link>
                        )
                    }
                    {
                        (contextByLocalStorage.role === 'SPECIAL_USER') && (
                            <Link to="/campaign/new" className='botones-sidebar'>
                                <div className={`lista ${location.pathname === '/campaign/new' ? 'listaactive' : ''}`}>
                                    <img src={menuIcon} alt=""/>
                                    <h1>Nuevas Campañas</h1>
                                </div>
                            </Link>
                        )
                    }
                    <div className="devider">
                        <hr/>
                    </div>
                </div>
                <div className="divcon2opc">
                    <div className="titulinho">
                        <h1>Informacíon</h1>
                    </div>
{/*                     <Link to="/analytics" className='botones-sidebar'>
                        <div className={`lista ${location.pathname === '/analytics' ? 'listaactive' : ''}`} >
                            <img src={LogoAnalytics} alt=""/>
                            <h1>Estadísticas</h1>
                        </div>
                    </Link> */}
                    {
                        (contextByLocalStorage.role === 'ADMIN' || contextByLocalStorage.role === 'LEADER') && (
                            <Link to="/leaderboard" className='botones-sidebar'>
                                <div className={`lista ${location.pathname === '/leaderboard' ? 'listaactive' : ''}`}>
                                    <img src={LogoLeaderboard} alt=""/>
                                    <h1>Leaderboard</h1>
                                </div>
                            </Link>
                        )
                    }
                                        {
                        (contextByLocalStorage.role === 'ADMIN') && (
                            <Link to="/paymentMethods" className='botones-sidebar'>
                                <div className={`lista ${location.pathname === '/paymentMethods' ? 'listaactive' : ''}`}>
                                    <img src={paymentIcon} alt=""/>
                                    <h1>Métodos de Pago</h1>
                                </div>
                            </Link>
                        )
                    }
                    <div className="devider">
                        <hr/>
                    </div>
                </div>
                <div className="divcon2opc">
                    <div className="titulinho">
                        <h1>Cuenta</h1>
                    </div>
                    {
                    <Link to="/profile" className='botones-sidebar'>
                        <div className={`lista ${location.pathname === '/profile' ? 'listaactive' : ''}`}>
                            <img src={profileIcon} alt=""/>
                            <h1>Mi Perfil</h1>
                        </div>
                    </Link>
                    }
                    {
                        contextByLocalStorage.role === 'ADMIN' && <Link to="/register" className='botones-sidebar'>
                        <div className={`lista ${location.pathname === '/register' ? 'listaactive' : ''}`}>
                            <img src={LogoRegisterUser} alt=""/>
                            <h1>Registrar Usuario</h1>
                        </div>
                    </Link>
                    }
                    {
                        (contextByLocalStorage.role === 'ADMIN' || contextByLocalStorage.role === 'LEADER') && (
                            <Link to="/user/delete" className='botones-sidebar'>
                                <div className="lista">
                                    <img src={LogoDeleteUser} alt=""/>
                                    <h1>Eliminar Usuario</h1>
                                </div>
                            </Link>
                        )
                    }
                    <div className="devider">
                        <hr/>
                    </div>
                    <Link to="/" className='botones-sidebar'>
                        <div className="lista" style={{marginTop: 20}} onClick={() => {
                            localStorage.clear();
                        }}>
                            <img src={exitIcon} alt=""/>
                            <h1>Cerrar Sesión</h1>
                        </div>
                    </Link>
                </div>
            </div>
        </div> 
    )
    }
export default SideBar;