import React, { useEffect, useState } from 'react'
import SideBar from '../home/SideBar'
import shopIcon from '../../assets/icon/shop-filled.svg';
import { API_URL } from '../../constants.ts';
import axios from 'axios';
import { Loading } from '../Loading/Loading.jsx';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export const ValidateSales = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [sales, setSales] = useState([]);

    const getSalesData = async () => {
        await axios.get(API_URL + `/sale/get-by-status-and-validator/${contextByLocalStorage.id}/PENDING?isOld=false`, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                "ngrok-skip-browser-warning":"any"
              }
        })
        .then((res) => {
            if(res.status === 200) {
                setData(res.data);
            }
        })
        .catch((err) => console.log(err))
    };

    useEffect(() => {
      if(contextByLocalStorage.token) {
        getSalesData();
      }
    }, []);

    const getDate = (date = '2024-01-10T03:00:00.000+00:00') => {
        const dateSplit = date.split('T');
        return dateSplit[0];
    }

    const addOrRemoveSale = (saleId) => {
        if(!sales.includes(saleId)) {
            const s = [...sales];
            s.push(saleId);
            setSales(s);
        }else {
            const s = sales.filter(sale => sale !== saleId);
            setSales(s);
        }
    }
    
    const [showErrorLabel, setShowErrorLabel] = useState(false);

    const handleValidateSales = async() => {
        setShowErrorLabel(false);
        if(sales.length === 0) {
            setShowErrorLabel(true);
            return;
        }
        setIsLoading(true);
        await axios.put(`${API_URL}/sale/validate-sales`, sales, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br'
            },
        })
        .then((res) => {
            if(res.status === 200) {
                if(contextByLocalStorage.token) {
                    getSalesData();
                }
            }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    };

    const [modalRejectIsOpen, setModalRejectIsOpen] = useState(false);

    const openRejectModal = () => {
        setModalRejectIsOpen(true);
    };
    
    const [campaignToReject, setCampaignToReject] = useState(null);
    const addOrRemoveCampaignToReject = async (campaignId) => {
        openRejectModal();
        setCampaignToReject(campaignId);
    }

    const closeRejectModal = () => {
        setModalRejectIsOpen(false);
    };

    const handleReject = async () => {
        setIsLoading(true);
        await axios.put(`${API_URL}/sale/reject-sale/${campaignToReject}?rejectReason=null`,null ,{
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br'
            },
        })
        .then((res) => {
            if(res.status === 200) {
                closeRejectModal();
                if(contextByLocalStorage.token) {
                    getSalesData();
                }
            }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    };

    const getFilePath = (imgPath) => {
        const separate = imgPath.split('/');
        const img = separate[separate.length - 1];
        console.log(img)
        return <a style={{
            textDecoration: 'none',
            color: '#FFF'
        }} href={`https://standoutcompany8.com/uploads/${img}`} target='_blank'>Ver</a>;
    };

  return (
    <div className='padre'>
        <SideBar/>
        <div className='validate-sales-container'>
            <div className='validate-sales-header-container'>
                <h1 className='sales-title'>Validar Ventas</h1>
                <p className='sales-subtitle'>Acá están los detalles de tus ventas</p>
            </div>
            <div className='validate-sales-table-container' style={{overflowY: 'auto', maxHeight: '790px'}}>
                <div style={{margin: 20}}>
                    <div>
                        <div className='table-header-container'>
                            <img src={shopIcon} className="icon shop-fill" alt="" />
                            <p className='sales-title-table'>Ventas</p>
                        </div>
                    </div>
                    <div>
                    <table style={{borderCollapse: 'collapse', marginTop: 20}}>
                        <thead>
                            <tr>
                                <th className='validate-sales-th'>Vendedor</th>
                                <th className='validate-sales-th'>Paquete</th>
                                <th className='validate-sales-th'>Precio</th>
                                <th className='validate-sales-th'>Método de Pago</th>
                                <th className='validate-sales-th'>Fecha</th>
                                <th className='validate-sales-th'>MTC</th>
                                <th className='validate-sales-th'>Nombre</th>
                                <th className='validate-sales-th'>Nota</th>
                                <th className='validate-sales-th'>Ticket</th>
                                <th className='validate-sales-th'>Confirmar</th>
                            </tr>
                        </thead>
                        <div style={{display: 'flex', justifyContent: 'center', width: '75vw', position:'absolute'}}>
                            {isLoading && <Loading/>}
                        </div>
                        <tbody>
                            {
                                data.length === 0 && (
                                    <div className='no-results-container'>
                                        <p className='no-results-msg'>No hay campañas que mostrar.</p>
                                    </div>
                                )
                            }
                            {
                                data.length > 0 && data.map((user) => <tr key={user.id}>
                                <td className='validate-sales-td'>{user.seller.username}</td>
                                <td className='validate-sales-td'>{user.pack}</td>
                                <td className='validate-sales-td'>${user.price}</td>
                                <td className='validate-sales-td'>{user.paymentMethod}</td>
                                <td className='validate-sales-td'>{getDate(user.date)}</td>
                                <td className='validate-sales-td'>{user.mtc ? user.mtc : '-'}</td>
                                <td className='validate-sales-td'>{user.clientName}</td>
                                <td className='validate-sales-td'>{user.note ? user.note : '-'}</td>
                                <td className='validate-sales-td'>{getFilePath(user.filePath)}</td>
                                <td className='validate-sales-td'>
                                <div>
                                        <button 
                                            style={{ marginRight: 10 }}
                                            className='btn-table-sales'
                                            onClick={() => addOrRemoveCampaignToReject(user.id)}>❌
                                        </button>
                                        <button style={{background: sales.includes(user.id) && '#E7CC97', color: sales.includes(user.id) && 'black'}}
                                            className='btn-table-sales'
                                            onClick={() => addOrRemoveSale(user.id)}>
                                                ✓
                                        </button>
                                    </div>
                                </td>
                                <Modal
                                isOpen={modalRejectIsOpen}
                                onRequestClose={closeRejectModal}
                                contentLabel="Agregar Proveedor Modal"
                                className="modal-content"
                                shouldCloseOnOverlayClick={false}
                                overlayClassName="modal-overlay"
                                style={{
                                    overlay: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo con opacidad
                                    zIndex: 1000,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    },
                                    content: {
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: '#1E1E1E',
                                    width: '600px',
                                    height: '200px',
                                    margin: 'auto',
                                    zIndex: 1001,
                                    borderRadius: 16
                                    },
                                }}
                            >
                            <div className='modal-container-campaigns'>
                                <div className='container-header-modal'>
                                    <h2 className='links-label-title' style={{fontSize: 'inherit'}}>Rechazar campaña?</h2>
                                    <button className='btn-close-modal-ca' onClick={closeRejectModal}>Cerrar</button>
                                </div>
                                <button style={{cursor: 'pointer'}} onClick={() => handleReject()}>Confirmar</button>
                            </div>
                            </Modal>
                            </tr>
                            
                            )
                            }
                        </tbody>
                    </table>
                    {showErrorLabel && <p className='error-label' style={{textAlign: 'center'}}>Debe seleccionar al menos una venta para continuar.</p>}
                    <div className='btn-container-table-sales'>
                        {data.length > 0 && <button disabled={isLoading} onClick={() => handleValidateSales()} className='confirm-sales-btn'>Continuar</button>}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
