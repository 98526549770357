import React, { useEffect, useState } from 'react';
import "../../../src/css/dashboard/dashboard.css";
import plusIcon from '../../assets/icon/plus-black.svg';
import goldenWalletIcon from '../../assets/icon/golde-wallet.svg';
import goldenCashIcon from '../../assets/icon/golden-cash.svg';
import goldenCrossIcon from '../../assets/icon/golden-cross.svg';
import goldenClockIcon from '../../assets/icon/golden-clock.svg';
import goldenShopIcon from '../../assets/icon/golden-shop.svg';
import groupIcon from '../../assets/icon/golden-group.svg';
import upGreenIcon from '../../assets/icon/up-green.svg';
import calendarIcon from '../../assets/icon/Calendar.svg';
import downRedIcon from '../../assets/icon/down-red.svg';
import arrowIcon from '../../assets/icon/right-arrow.svg';
import dollarIcon from '../../assets/icon/bxs_dollar-circle.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../constants.ts';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart,Bar, Rectangle } from 'recharts';
import Modal from 'react-modal';
import { Loading } from '../Loading/Loading.jsx';

Modal.setAppElement('#root');

const Dashboard = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
    const [structureData, setStructureData] = useState({
        name: '',
        leaders: [{
            userId: contextByLocalStorage.id,
            percentage: 1
        }],
        validatorId: null,
        campaignValidatorId: null
    });

    const [selectedValidator, setSelectedValidator] = useState('');
    const [selectedValidatorCampaign, setSelectedValidatorCampaign] = useState('');

    const openModal = () => {
        setModalIsOpen(true);
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const closeModal = () => {
        setModalIsOpen(false);
    }
    
    const currentDate = new Date();
    const oneWeekBefore = new Date(currentDate);
    const oneMonthBefore = new Date(currentDate);
    const oneYearBefore = new Date(currentDate);
    oneYearBefore.setFullYear(currentDate.getFullYear() - 1);
    oneWeekBefore.setDate(currentDate.getDate() - 7);
    oneMonthBefore.setMonth(currentDate.getMonth() - 1);;

    const [data, setData] = useState(null);

    const fetchAnalyticsData = async() => {
        await axios.post(`${API_URL}/analytics/financial-performance-report`, {
            "userId": contextByLocalStorage.id,
            "targetDate": new Date().toISOString()
        },{
            method: "POST",
            headers: {
              'Authorization': `Bearer ${contextByLocalStorage.token}`,
            },
        })
        .then((res) => {
            const r = res.data;
            if(res.status === 200) {
                setData(r);
            }
        })
        .catch((err) => console.log(err))
    }

    const [teamData, setTeamData] = useState(null);
    const [showCreateStructure, setShowCreateStructure] = useState(false);
    const fetchTeam = async() => {
        await axios.get(API_URL + `/api/structure/structure/by-leader/${contextByLocalStorage.id}`, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                "ngrok-skip-browser-warning":"any"
              }
        })
        .then((res) => {
            if(res.status === 200) {
                setTeamData(res.data);
                if(!res.data) {
                    setShowCreateStructure(true)
                }
            }
        })
        .catch((err) => console.log(err));
    };

    const [dataForBarGraph, setDataForGraphBar] = useState(null);
    const fetchNetoGraph = async(timePeriod = 'MONTHLY') => {
        await axios.post(`${API_URL}/analytics/graphic/gross-report`, {
            userId: contextByLocalStorage.id,
            timePeriod,
            currentDate,
            financialReport: 'PROFIT'
        },{
            method: "POST",
            headers: {
              'Authorization': `Bearer ${contextByLocalStorage.token}`,
            },
        })
        .then((res) => {
           if(res.status === 200) {
            let dataToGraph = [];
            res.data.map((d) => dataToGraph.push({name: d.name, uv: d.value}));
            setDataForGraphBar(dataToGraph);
        }
        })
        .catch((err) => console.log(err));
    };

    const [dataForLinearGraph, setDataForLinearGraph] = useState(null);
    const fetchTotalGraph = async(timePeriod = 'MONTHLY') => {
        await axios.post(`${API_URL}/analytics/graphic/gross-report`, {
            userId: contextByLocalStorage.id,
            timePeriod,
            currentDate,
            financialReport: 'GROSS'
        },{
            method: "POST",
            headers: {
              'Authorization': `Bearer ${contextByLocalStorage.token}`,
            },
        })
        .then((res) => {
           if(res.status === 200) {
            let dataToGraph = [];
            res.data.map((d) => dataToGraph.push({name: d.name, ventas: d.value}));
            setDataForLinearGraph(dataToGraph);
        }
        })
        .catch((err) => console.log(err));
    };


    useEffect(() => {
        fetchTotalGraph();
        fetchNetoGraph();
        fetchAnalyticsData();
        fetchTeam();
    }, []);

    const [validatorsByUsername, setValidatorsByUsername] = useState([]);
    const [validatorsCampaignByUsername, setValidatorsCampaignByUsername] = useState([]);
    const [showNoResultsLabel, setShowNoResultsLabel] = useState(false);
    const [showNoResultsCampaign, setShowNoResultsCampaign] = useState(false);

    const fetchValidatorsByUsername = async(username) => {
        
        await axios.get(API_URL + `/user/by-username/${username}/VALIDATOR`, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                "ngrok-skip-browser-warning":"any"
              }
        })
        .then((res) => {
            if(res.status === 200) {
                setValidatorsByUsername(res.data);
                if(res.data.length === 0) {
                    setShowNoResultsLabel(true);
                } else {
                    setShowNoResultsLabel(false);
                }
            }
        })
        .catch((err) => console.log(err));
    };

    const fetchValidatorsCampaignByUsername = async(username) => {
        
        await axios.get(API_URL + `/user/by-username/${username}/SPECIAL_USER`, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                "ngrok-skip-browser-warning":"any"
              }
        })
        .then((res) => {
            if(res.status === 200) {
                setValidatorsCampaignByUsername(res.data);
                if(res.data.length === 0) {
                    setShowNoResultsCampaign(true);
                } else {
                    setShowNoResultsCampaign(false);
                }
            }
        })
        .catch((err) => console.log(err));
    };

    useEffect(() => {
      if(selectedValidator.length === 0) {
        setValidatorsByUsername([]);
        setShowNoResultsLabel(false);
      }
    }, [selectedValidator])
    
    useEffect(() => {
        if(selectedValidatorCampaign.length === 0) {
          setValidatorsCampaignByUsername([]);
          setShowNoResultsCampaign(false);
        }
      }, [selectedValidatorCampaign]);

      const [showRequiredDataError, setShowRequiredDataError] = useState(false);

      const [isLoading, setIsLoading] = useState(false);

      const handleCreateStructure = async () => {
        setIsLoading(true);
        setShowRequiredDataError(false);
        if(structureData.name === '' || structureData.campaignValidatorId === null || structureData.validatorId === null) {
            setShowRequiredDataError(true);
            setIsLoading(false);
            return;
        }else{ setShowRequiredDataError(false)}
        await axios.post(`${API_URL}/api/structure/create`, structureData,{
            method: "POST",
            headers: {
              'Authorization': `Bearer ${contextByLocalStorage.token}`,
            },
        })
        .then((res) => {
            if(res.status === 201) {
                closeModal();
                fetchTotalGraph();
                fetchNetoGraph();
                fetchAnalyticsData();
                fetchTeam();
            }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
      };

    return (
        <div className="">
            <div className='header-container-dashboard'>
                <div>
                    <h1 className='header-title'>Bienvenido de nuevo, {contextByLocalStorage.username}</h1>
                    <p className='header-subtitle'>Como líder tienes la posibilidad de crear un equipo</p>
                </div>
                <div>
                {
                    (showCreateStructure && contextByLocalStorage.role !== 'EMPLOYEE') && (
                        <button className='create-structure-button' onClick={() => openModal()}>
                            <img className='iconImageCreate' src={plusIcon} alt="plus icon create structure" />
                            Crear Estructura
                        </button>
                    )
                }
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        contentLabel="Crear estructura"
                        className="modal-content-profile"
                        shouldCloseOnOverlayClick={false}
                        overlayClassName="modal-overlay"
                        style={{
                            overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1000,
                            },
                            content: {
                            position: 'absolute',
                            backgroundColor: '#1E1E1E',
                            width: '559px',
                            height: '484px',
                            borderRadius: 16,
                            },
                        }}
                        >
                        <div className='modal-create-structure'>
                            <div className='modal-input-container'>
                               <span className='label'>Nombre de la estructura</span>
                               <input value={structureData.name} className='input-form' type="text" placeholder='Nombre..'
                                    onChange={(e) => setStructureData((prev) => ({
                                    ...prev,
                                    name: e.target.value
                                    })
                                )}
                               />
                            </div>
                            {isLoading && <Loading/>}
{/*                             <div className='modal-checkbox-container'>
                                <input type="checkbox" />
                                <span className='label'>Utilizar validadores de equipo</span>
                            </div> */}
                            <div className='modal-input-container'>
                               <span className='label'>Asignar validador</span>
                               <input value={selectedValidator} className='input-form' type="text" placeholder='Buscar..' 
                                onChange={(e) => {
                                    setSelectedValidator(e.target.value);
                                    if( selectedValidator.length >= 2) {
                                        fetchValidatorsByUsername(e.target.value);
                                    }
                                }}
                               />

                                <div className='result-container'>
                                    {showNoResultsLabel && (
                                        <p className='label-result'>No se encontraron resultados</p>
                                    )}
                                        {validatorsByUsername.map((validator) => (
                                        <div className='result-option' key={validator.id} onClick={() => {
                                            setStructureData((prev) => ({
                                                ...prev,
                                                validatorId: validator.id
                                                })
                                            )
                                            setSelectedValidator(validator.username);
                                            setValidatorsByUsername([]);
                                        }}>
                                            <p className='label-result'>{validator.username}</p>
                                        </div>
                                        ))}
                                </div>

                            </div>
                            <div className='modal-input-container'>
                               <span className='label'>Asignar validador de campañas</span>
                               <input value={selectedValidatorCampaign} className='input-form' type="text" placeholder='Nombre..'
                                onChange={(e) => {
                                    setSelectedValidatorCampaign(e.target.value);
                                    if( selectedValidatorCampaign.length >= 2) {
                                        fetchValidatorsCampaignByUsername(e.target.value);
                                    }
                                }}
                               />
                                <div className='result-container'>
                                    {showNoResultsCampaign && (
                                        <p className='label-result'>No se encontraron resultados</p>
                                    )}
                                        {validatorsCampaignByUsername.map((validator) => (
                                        <div className='result-option' key={validator.id} onClick={() => {
                                            setStructureData((prev) => ({
                                                ...prev,
                                                campaignValidatorId: validator.id
                                                })
                                            );
                                            setSelectedValidatorCampaign(validator.username);
                                            setValidatorsCampaignByUsername([]);
                                        }}>
                                            <p className='label-result'>{validator.username}</p>
                                        </div>
                                        ))}
                                </div>
                            </div>
                            {
                                showRequiredDataError && <p className='error-label mod-err'>Datos incompletos.</p>
                            }
                            <div className='modal-button-container'>
                                <button className='modal-btn-cancel' disabled={isLoading} onClick={closeModal}>Cancelar</button>
                                <button className='modal-btn-confirm' disabled={isLoading} onClick={() => handleCreateStructure()}>Confirmar</button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
            {
                data && <div className='container-analytic-boxes'>
                <div className='analytic-box'>
                    <div className='box-header-container'>
                        <img className='box-img' src={goldenWalletIcon} alt="wallet icon" />
                        <span className='box-title'>Ventas Totales</span>
                    </div>
                    <div className='box-data-container'>
                        <span className='box-data'>${data.grossMetricSummary.totalValue}</span>
                    </div>
                    <div className='box-trend-container'>
                            <img className='box-trend-image' src={data.grossMetricSummary.variationPercentage >= 0 ? upGreenIcon : downRedIcon} alt="trend icon" />
                            <span className='box-trend-label'>{data.grossMetricSummary.variationPercentage}%</span>
                    </div>
                </div>

                <div className='analytic-box'>
                    <div className='box-header-container'>
                        <img className='box-img' src={goldenCashIcon} alt="cash icon" />
                        <span className='box-title'>Beneficio Neto</span>
                    </div>
                    <div className='box-data-container'>
                        <span className='box-data'>${data.profitMetricSummary.totalValue}</span>
                    </div>
                    <div className='box-trend-container'>
                            <img className='box-trend-image' src={data.profitMetricSummary.variationPercentage >= 0 ? upGreenIcon : downRedIcon} alt="trend icon" />
                            <span className='box-trend-label'>{data.profitMetricSummary.variationPercentage}%</span>
                    </div>
                </div>

                <div className='analytic-box'>
                    <div className='box-header-container'>
                        <img className='box-img' src={goldenCrossIcon} alt="cash icon" />
                        <span className='box-title'>Ventas Rechazadas</span>
                    </div>
                    <div className='box-data-container'>
                        <span className='box-data'>${data.rejectedSalesMetricSummary.totalValue}</span>
                    </div>
                    <div className='box-trend-container'>
                            <img className='box-trend-image' src={data.rejectedSalesMetricSummary.variationPercentage >= 0 ? upGreenIcon : downRedIcon} alt="trend icon" />
                            <span className='box-trend-label'>{data.rejectedSalesMetricSummary.variationPercentage}%</span>
                    </div>
                </div>

                <div className='analytic-box'>
                    <div className='box-header-container'>
                        <img className='box-img' src={goldenShopIcon} alt="cash icon" />
                        <span className='box-title'>Ventas de Hoy</span>
                    </div>
                    <div className='box-data-container'>
                        <span className='box-data'>${data.todaySalesMetricSummary.totalValue}</span>
                    </div>
                    <div className='box-trend-container'>
                            <img className='box-trend-image' src={data.todaySalesMetricSummary.variationPercentage >= 0 ? upGreenIcon : downRedIcon} alt="trend icon" />
                            <span className='box-trend-label'>{data.todaySalesMetricSummary.variationPercentage}%</span>
                    </div>
                </div>

                <div className='analytic-box'>
                    <div className='box-header-container'>
                        <img className='box-img' src={goldenClockIcon} alt="cash icon" />
                        <span className='box-title'>Ventas Pendientes</span>
                    </div>
                    <div className='box-data-container'>
                        <span className='box-data'>${data.pendingSalesMetricSummary.totalValue}</span>
                    </div>
                    <div className='box-trend-container'>
                            <img className='box-trend-image' src={data.pendingSalesMetricSummary.variationPercentage >= 0 ? upGreenIcon : downRedIcon} alt="trend icon" />
                            <span className='box-trend-label'>{data.pendingSalesMetricSummary.variationPercentage}%</span>
                    </div>
                </div>

                {
                    teamData && (
                        <div className='analytic-box'>
                            <div className='box-header-container'>
                                <img className='box-img' src={groupIcon} alt="cash icon" />
                                <span className='box-title'>Grupo</span>
                            </div>
                            <div className='box-group-data-container'>
                                <img className='box-group-img' src={groupIcon} alt="user image" />
                                <div className='box-group-body'>
                                    <span className='box-group-name'>{teamData.name}</span>
                                    <span className='box-group-quantity'><span className='box-group-quantity-number'>{teamData.sellers.length}</span> People in Group</span>
                                </div>
                                <Link  to="/myTeam">
                                    <img className='box-arrow-img' src={arrowIcon} alt="arrow icon" />
                                </Link>
                            </div>
                        </div>
                    )
                }

            </div>
            }

            <div className='dashboard-graphs-container'>
                <div className='dashboard-graph-box'>
                    <div className='box-header-container' style={{justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img className='box-img' src={goldenWalletIcon} alt="cash icon" />
                            <span className='box-title title-graph'>Ventas Totales</span>
                        </div>
                        <div className="select-con-imagen" style={{marginBottom: 20}}>
                            <span className="imagen-select">
                                <img src={calendarIcon} alt="Imagen de select" />
                            </span>
                            <select className='select-graph' onChange={(e) => fetchTotalGraph(e.target.value)}>
                                <option value='WEEKLY'>Weekly</option>
                                <option value='MONTHLY'>Monthly</option>
                                <option value='YEARLY'>Year</option>
                            </select>
                        </div>
                    </div>

                    {
                        dataForLinearGraph && (
                            <div style={{height: '100%'}}>
                                <ResponsiveContainer width="100%" height="80%">
                                    <LineChart width={500} height={300} data={dataForLinearGraph} margin={{
                                            top: 5,
                                            right: 50,
                                            left: 20,
                                            bottom: 30,
                                        }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Line type="monotone" dataKey="ventas" stroke="#E7CC97" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        )
                    }
                </div>
                <div className='dashboard-graph-box'>
                    <div className='box-header-container' style={{justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img className='box-img' src={dollarIcon} alt="cash icon" />
                            <span className='box-title title-graph'>Beneficio Neto</span>
                        </div>
                        <div className="select-con-imagen" style={{marginBottom: 20}}>
                            <span className="imagen-select sarasa">
                                <img src={calendarIcon} alt="Imagen de select" />
                            </span>
                            <select className='select-graph' onChange={(e) => fetchNetoGraph(e.target.value)}>
                                <option value='MONTHLY'>Monthly</option>
                                <option value='WEEKLY'>Weekly</option>
                                <option value='YEARLY'>Year</option>
                            </select>
                        </div>
                    </div>

                    {
                        dataForBarGraph && (
                            <div style={{height: '100%'}}>
                                <ResponsiveContainer width="100%" height="80%">
                                    <BarChart
                                    width={500}
                                    height={300}
                                    data={dataForBarGraph}
                                    margin={{
                                        top: 5,
                                        right: 50,
                                        left: 20,
                                        bottom: 30,
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="uv" fill="#E7CC97"  activeBar={<Rectangle fill="#E8C47F" stroke="#2E2E2E" />} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>   
    )
    }
export default Dashboard;
