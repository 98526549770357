import React, { useEffect, useState } from 'react'
import PersonaLogo from "../../../src/assets/icon/persona.svg";
import CandadoLogo from "../../../src/assets/icon/candado.svg";
import axios from 'axios';
import WorkLogo from "../../../src/assets/icon/work.svg";
import OjoLogo from "../../../src/assets/icon/ojo.svg";
import { API_URL } from '../../constants.ts'
import '../../css/register/register.css';
import { Loading } from '../Loading/Loading';

const RegisterCard = () => {
  const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
  const [showPassword, setShowPassword] = useState(false);
  const [showIncompleteDataError, setShowIncompleteDataError] = useState(false);
  const [showSuccessLabel, setShowSuccessLabel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    role: '',
    teamId: null,
    birthdate: new Date(),
  });

  useEffect(() => {
    if(contextByLocalStorage) {
      axios.get(`${API_URL}/api/structure/structure/by-leader/${contextByLocalStorage.id}`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${contextByLocalStorage.token}`,
        },
    }).then((res) => {
      if(res.status === 200) {
        setFormData((prev) => ({
          ...prev,
          teamId: res.data.id
        }));
      }
    })
    }
  }, []);
  

  const handleSubmit = async () => {
    setShowIncompleteDataError(false);
    if(formData.role === '' || formData.username === '' || formData.password === '') {
      setShowIncompleteDataError(true);
      return;
    }
    setIsLoading(true);
    await axios.post(`${API_URL}/auth/register`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip, deflate, br'
      },
    })
    .then((res) => {
      if(res.status === 200) {
        setShowSuccessLabel(true);
        setFormData({
          username: '',
          password: '',
          role: '',
          birthdate: new Date(),
        });
        setTimeout(() => {
          setShowSuccessLabel(false);
        }, 3000);
      }
    })
    .catch((err) => console.log(err))
    .finally(() => setIsLoading(false))
  };

  return (
    <div className='register-wrapper'> 
        <div className="register">
          {
            isLoading && <Loading/>
          }
          <h1 className="title">Registrar usuario</h1>
          <div className="username-register">
            <img src={PersonaLogo} type="iconPersona" className="icon" alt="" />
            <input className="usernameInput" type="username" name="username" placeholder="Usuario"
              value={formData.username}
              onChange={(e) => setFormData((prev) => ({
                ...prev,
                username: e.target.value
                })
              )}
            /> 
          </div>
          <div className="password-register">
            <img src={CandadoLogo} type="iconCandado" className="icon1" alt="" /> 
            <input className="passwordInput" type={showPassword ? 'text' : 'password'} name="password" placeholder="Contraseña"
              value={formData.password}
              onChange={(e) => setFormData((prev) => ({
              ...prev,
              password: e.target.value
            })
              )}
            /> 
            <img src={OjoLogo} type="iconOjo" className="icon2" alt="" onClick={() => setShowPassword(!showPassword)} />
          </div>
          <div className='role'>
            <img src={WorkLogo} type="iconCandado" className="icon1" alt="" /> 
            <select className='role-select'
              onChange={(e) => setFormData((prev) => ({
                ...prev,
                role: e.target.value
              }))}>
              <option defaultValue="ROLE" disabled hidden selected>Role</option>
              <option value="LEADER">Lider</option>
              <option value="EMPLOYEE">Vendedor</option>
              <option value="VALIDATOR">Validador de ventas</option>
              <option value="SPECIAL_USER">Validador de campañas</option>
            </select>
            { showIncompleteDataError && <p className='incomplete-error'>Campos obligatorios incompletos.</p>}
            { showSuccessLabel && <p className='success-label'>Usuario registrado con éxito</p>}
          </div>
          <p className="captcha">This site is protected by reCAPTCHA and the Google Terms & Privacy Policy.</p>
          <button onClick={handleSubmit} disabled={isLoading} className="continue-button-register">Registrar</button>
        </div>
       
    </div>
  )
}
export default RegisterCard;