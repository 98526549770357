import React, { useEffect, useState } from 'react'
import metricIcon from '../../../src/assets/icon/metric.svg';
import Modal from 'react-modal';
import '../../css/home/assignPercentage.css';
import axios from 'axios';
import { API_URL } from '../../constants.ts';

export const AssignPercentage = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
    const openModal = () => {
        setModalIsOpen(true);
    };
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const closeModal = () => {
        setModalIsOpen(false);
    };
    
    const [childs, setChilds] = useState();

    const getChildsFromLeader = async() => {
        await axios.post(`${API_URL}/api/structure/childs-from-leader`, {
            leaderId: contextByLocalStorage.id,
            startDate: null,
            endDate: null
        }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${contextByLocalStorage.token}`
            },
        })
        .then((res) => {
            if(res.status === 200) {
                localStorage.setItem('context', JSON.stringify({
                    ...contextByLocalStorage,
                    childsFromLeader: res.data
                }));
                setChilds(res.data);
            }
        })
        .catch((err) => console.log(err))
    };

    useEffect(() => {
      if(contextByLocalStorage.childsFromLeader) {
        setChilds(contextByLocalStorage.childsFromLeader);
      }else {
        getChildsFromLeader();
      }
    }, [])
    

    const [searchTerm, setSearchTerm] = useState('');

    const filteredChilds = (array, username) => {
        const usernameLower = username.toLowerCase();
        return array.filter(item => item.node.user.username.toLowerCase().includes(usernameLower));
      };

    const [filteredUsers, setFilteredUsers] = useState([])

    const [newPercentage, setNewPercentage] = useState(0);

    const updatePercentage = async (child) => {
        await axios.put(`${API_URL}/api/structure/update-percentage`, {
            leaderId: contextByLocalStorage.id,
            sellerId: child.node.user.id,
            newPercentage: newPercentage
        }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${contextByLocalStorage.token}`
            },
        })
        .then((res) => {
            if(res.status === 200) {
                window.location.reload()
            }
        })
        .catch((err) => console.log(err))
    };

  return (
    <div>
        <button className='btn-my-team-side' onClick={openModal}>
            <img src={metricIcon} alt="Icono" className="button-icon"/>
            Asignar porcentaje
        </button>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Asignar porcentaje"
            className="modal-content-percentage"
            shouldCloseOnOverlayClick={false}
            overlayClassName="modal-overlay"
            style={{
                overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                },
                content: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#1E1E1E',
                    width: '1200px',
                    height: '650px',
                    margin: 'auto',
                    zIndex: 1001,
                    borderRadius: 16
                },
            }}
        >
            <div className='header-assign-container'>
                <span className='title-label'>Vendedores</span>
                <button onClick={closeModal} className='btn-close-percentage'>cerrar</button>
            </div>
            <div className='input-assign-container'>
                <input onChange={(e) => {
                    setSearchTerm(e.target.value)
                    setFilteredUsers(filteredChilds(childs, e.target.value));
                }} type="text" placeholder='Buscar vendedor...' />
            </div>
            <div className='table-container percentage-table'>
        <table className="tabla">
            <tr>
                <th className="Titulo-nombre-activity">Nombre</th>
                <th className="Titulo-ventas-activity">Porcentaje</th>
                <th className="Titulo-ventaspendientes-activity">Confirmar</th>
            </tr>
            <tbody style={{overflowY:'auto'}}>
            {
                (childs && searchTerm === '') && (
                    childs.map(child => <tr>
                    <td className="tabla-nombrefoto">
                        <span className="tabla-Nombre">{child.node.user.username}</span>
                    </td>
                    <td className="tabla-totalventas">
                        <input className='percentage-input' type="number" min="0" max="1" onChange={(e) => setNewPercentage(e.target.value)} /><span style={{color: 'white', marginLeft: 5}}>%</span>
                    </td>
                    <td><button className='btn-confirm-percentage' onClick={() => updatePercentage(child)}>confirmar</button></td>  
                </tr>)
                )
            }
            {
                (filteredUsers.length > 0 && searchTerm !== '') && (
                    filteredUsers.map(child => <tr>
                    <td className="tabla-nombrefoto">
                        <span className="tabla-Nombre">{child.node.user.username}</span>
                    </td>
                    <td className="tabla-totalventas">
                        <input className='percentage-input' type="number" min="0" max="1" value={newPercentage} onChange={(e) => setNewPercentage(e.target.value)} /><span style={{color: 'white', marginLeft: 5}}>%</span>
                    </td>
                    <td><button className='btn-confirm-percentage' onClick={() => updatePercentage(child)}>confirmar</button></td>  
                </tr>)
                )
            }
            </tbody>
        </table>
    </div>
        </Modal>
    </div>
  )
}
