import React, { useState } from 'react'
import SideBar from '../home/SideBar.jsx';
import '../../css/myProfile/myProfile.css';
import userIcon from '../../assets/icon/gold-user.svg';
import cameraIcon from '../../assets/icon/camera.svg';
import lockIcon from '../../assets/icon/lock.svg';
import Modal from 'react-modal';
import axios from 'axios';
import { API_URL } from '../../constants.ts';

Modal.setAppElement('#root');

export const MyProfile = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));

    const openConfirmModal = () => {
        setModalIsOpen(true);
    };
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const closeConfirmModal = () => {
        setModalIsOpen(false);
    }

    const [selectedImage, setSelectedImage] = useState(null);

    const handleFileChange = (event) => {
      const file = event.target.files[0];
  
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
      }
    };

    const [passwordData, setPasswordData] = useState({
        oldPassword: '',
        newPassword: ''
    });

    const [showErrorLabel, setShowErrorLabel] = useState(false);
    const [showSuccessLabel, setShowSuccessLabel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handlePasswordChange  = async () => {
        if(passwordData.newPassword.length < 4) {
            setShowErrorLabel(true);
            return;
        }
        setShowErrorLabel(false);
        setIsLoading(true);
        await axios.put(`${API_URL}/user/change-user-password`, passwordData, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br'
            },
        })
        .then((res) => {
            if(res.status === 200) {
                setShowSuccessLabel(true);
                closeConfirmModal();
                setTimeout(() => {
                    setShowSuccessLabel(false);
                }, 2500);
            }
        })
        .catch((err) => console.log(err))
        .finally(() => {
            setIsLoading(false);
            setPasswordData({oldPassword: '', newPassword: ''})
        })
    };

  return (
    <div className='padre'>
        <SideBar/>
    <div className='profile-container'>
        <div className='profile-box'>
            <div className='profile-form'>
                <h1 className='profile-title'>Mi Perfil</h1>
                
                <div className='input-file-container'>
                    <label htmlFor="fileInput" className="file-label">
                    {selectedImage ? (
                        <img src={selectedImage} alt="Imagen seleccionada" className="file-image img-selected" />
                    ) : (
                        <img src={userIcon} alt="Seleccionar Archivo" className="file-image" />
                    )}  
                        <img src={cameraIcon} alt="seleccionar Imagen 2" className="small-image" />
                    </label>
                    <input type="file" id="fileInput" className="hidden-input" onChange={handleFileChange} />
                </div>

                <div style={{marginTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <h2 className='profile-username-label'>{contextByLocalStorage.username}</h2>
                    <span className='profile-ig-label'>{contextByLocalStorage.igAccounts.length && contextByLocalStorage.igAccounts[0].username || '-'}</span>
                    <div className='profile-rol-box'>
                        <span className='profile-role-label'>Rol: {contextByLocalStorage.role}</span>
                    </div>
                </div>
                <div>
                    <button className='profile-btn' onClick={openConfirmModal}>
                        <img src={lockIcon} alt="lock icon" className="profile-lock-icon" />
                        Cambiar contraseña
                    </button>
                    {
                        <div className='success-container'>
                            {showSuccessLabel && <p className='success-label'>Contraseña cambiada con éxito</p>}
                        </div>
                    }
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeConfirmModal}
                        contentLabel="Ascender a líder"
                        className="modal-content-profile"
                        shouldCloseOnOverlayClick={false}
                        overlayClassName="modal-overlay"
                        style={{
                            overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1000,
                            },
                            content: {
                            position: 'absolute',
                            backgroundColor: '#1E1E1E',
                            width: '800px',
                            height: '450px',
                            borderRadius: 16,
                            },
                        }}
                        >
                        <div className='modal-header-container'>
                            <h1 className='ascend-title title-change-profile'>Cambiar contraseña</h1>
                            <button className='modal-close' onClick={closeConfirmModal}>Cerrar</button>
                        </div>
                        <div>
                            <div className='change-password-input-container'>
                                <label className='change-password-input-label' >Contraseña Actual</label>
                                <input value={passwordData.oldPassword} type="password" className='change-password-input'
                                    onChange={(e) => setPasswordData((prev) => ({
                                        ...prev,
                                        oldPassword: e.target.value
                                    })
                                )}
                            />
                            </div>
                            <div className='change-password-input-container'>
                                <label className='change-password-input-label'>Contraseña Nueva</label>
                                <input value={passwordData.newPassword} className='change-password-input' type="password" 
                                    onChange={(e) => setPasswordData((prev) => ({
                                        ...prev,
                                        newPassword: e.target.value
                                    })
                                )}
                            />
                            </div>
                            <div className='change-password-error-container'>
                                {
                                    showErrorLabel && <p className='change-password-error-label'>La contraseña debe tener al menos 4 caracteres.</p>
                                }
                            </div>
                            <div className='change-password-button-container'>
                                <button className='change-password-button' onClick={handlePasswordChange} disabled={isLoading}>Cambiar Contraseña</button>
                            </div>
                        </div>
                    </Modal>

                </div>

            </div>
        </div>
    </div>
    </div>
  )
}
