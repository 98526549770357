import React from 'react';
import SideBar from '../components/home/SideBar';
import Leaderboard from '../components/home/Leaderboard';
import "../css/home/home.css";

const HomeAnalytics = () => {
  return (
    <div className="padre">
        <SideBar />
        <Leaderboard />     
    </div> 
  );
}
export default HomeAnalytics;