import React, { useState, useContext } from 'react'
import Modal from 'react-modal';
import addIcon from '../../../src/assets/icon/add.svg';
import axios from 'axios';
import { API_URL } from '../../constants.ts';
import { Loading } from '../Loading/Loading.jsx';
import { Context } from '../../App.jsx';

Modal.setAppElement('#root');

export const AddVendor = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
    const [vendorWithoutStructure, setVendorWithoutStructure] = useState([]);

    const getAllUserWithOutStructure = async () => {
        if (contextByLocalStorage.token) {
            await axios.get(API_URL + '/user/all/without-structure', {
                headers: {
                    'Authorization': `Bearer ${contextByLocalStorage.token}`,
                    "ngrok-skip-browser-warning": "any"
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        setVendorWithoutStructure(res.data);
                    }
                })
                .catch((err) => console.log(err))
        }
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openAddVendor = () => {
        setModalIsOpen(true);
        getAllUserWithOutStructure();
    };

    const closeAddVendor = () => {
        setModalIsOpen(false);
    };

    const [filteredOptions, setFilteredOptions] = useState([]);

    const filterAccounts = (inputText) => {
        const filteredOptions = vendorWithoutStructure.filter(user =>
            user && user.username && user.username.toLowerCase().includes(inputText.toLowerCase())
        ).slice(0, 5);
        setFilteredOptions(filteredOptions);
    };

    const [searchText, setSearchText] = useState('');
    const [formData, setFormData] = useState({
        leaderId: contextByLocalStorage.id,
        sellerId: null,
        newPercentage: null
    })
    const [showOptions, setShowOptions] = useState(false);
    const [showErrorValidation, setShowErrorValidation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessLabel, setShowSuccessLabel] = useState(false);

    const { context, setContext } = useContext(Context);

    const handleSubmit = async () => {
        setIsLoading(true);
        setShowErrorValidation(false);
        if (!formData.sellerId || !formData.newPercentage) {
            setShowErrorValidation(true);
            setIsLoading(false);
            return;
        }
        await axios.post(`${API_URL}/api/structure/assign-sellers`, {
            "leaderId": formData.leaderId,
            "sellers": [
                {
                    "userId": formData.sellerId,
                    "percentage": parseFloat(formData.newPercentage)
                }
            ]
        }, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br'
            },
        })
            .then((res) => {
                if (res.status === 201) {
                    setFormData({
                        leaderId: contextByLocalStorage.id,
                        sellerId: null,
                        newPercentage: null
                    });
                    setSearchText('');
                    setShowSuccessLabel(true);
                    
                    closeAddVendor();
                    setContext({
                        ...context,
                        addVendorReload: true
                    });
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false))
    }

    return (
        <div>
            <button className='btn-my-team-side' onClick={openAddVendor}>
                <img src={addIcon} alt="Icono" className="button-icon" />
                Agregar usuario
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeAddVendor}
                contentLabel="Agregar Proveedor Modal"
                className="modal-content"
                shouldCloseOnOverlayClick={false}
                overlayClassName="modal-overlay"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo con opacidad
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                    content: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#1E1E1E',
                        width: '400px',
                        height: '400px',
                        margin: 'auto',
                        zIndex: 1001,
                        borderRadius: 16
                    },
                }}
            >
                <div className='input-container'>
                    <p className='label-input'>Buscar usuario:</p>
                    <input className='modal-input' value={searchText} type="text" onChange={(e) => {
                        setSearchText(e.target.value);
                        if (e.target.value !== '') {
                            setShowOptions(true);
                        } else {
                            setShowOptions(false);
                        }
                        filterAccounts(e.target.value);
                    }} />
                    {isLoading && <Loading />}
                    {
                        (filteredOptions.length > 0 && showOptions) && (
                            <div className='results-container'>
                                <ul>
                                    {filteredOptions.map((vendor, index) => (
                                        <li style={{
                                        }} key={index} onClick={() => {
                                            setSearchText(vendor.username);
                                            setFormData((prev) => ({
                                                ...prev,
                                                sellerId: vendor.id
                                            }));
                                            setShowOptions(false);
                                        }}>
                                            <p>{vendor.username}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )
                    }
                </div>
                <div className='input-container'>
                    <p className='label-input'>Porcentaje:</p>
                    <input className='modal-input' type="number" min="0" max="100"
                        onChange={(e) => setFormData((prev) => ({
                            ...prev,
                            newPercentage: e.target.value
                        })
                        )}
                    />
                    {showErrorValidation && <p className='error-label'>Completar los datos obligatorios.</p>}
                    {showSuccessLabel && <p className='success-label'>Porcentaje actualizado</p>}
                </div>
                <div className='btn-container'>
                    <button className='btn-modal' onClick={() => handleSubmit()} disabled={isLoading}>Confirmar</button>
                    <button className='btn-modal close' onClick={closeAddVendor} disabled={isLoading}>Cerrar</button>
                </div>
            </Modal>
        </div>
    )
}
