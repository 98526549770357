import React from 'react';
import "../../../src/css/home/home.css";
import { AddVendor } from '../modals/AddVendor';
import { AssignPercentage } from '../modals/AssignPercentage';

const HeaderMyTeam = () => {
    
    return(
        <div className="div1dashboard">
            <div>
                <h1 className="Titdashb">Mi Equipo</h1>
                <p className="Subtdash">Aquí esta la informacíon de tu equipo.</p>
            </div>
            <div className='buttons-container'>
                <AddVendor/>
                <AssignPercentage/>
            </div>


        </div>

    )
}
export default HeaderMyTeam;