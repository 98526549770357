import React, { useEffect, useState } from 'react'
import SideBar from '../home/SideBar'
import '../../css/delete-user/delete-user.css';
import AvatarIcon4 from "../../../src/assets/icon/Ellipse 213.svg";
import userIcon from "../../../src/assets/icon/gold-user.svg";
import searchIcon from "../../../src/assets/icon/search.svg";
import lockWhiteIcon from "../../../src/assets/icon/lock-white.svg";
import crossWhite from "../../../src/assets/icon/cross_white.svg";
import Modal from 'react-modal';
import { API_URL } from '../../constants.ts';
import axios from 'axios';

Modal.setAppElement('#root')

export const DeleteUser = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));

    const openConfirmDeleteUserModal = (userId) => {
        setModalDeleteIsOpen(true);
        setUserIdToDelete(userId);
    };

    const openChangePasswordModal = (userId) => {
        setModalChangePasswordIsOpen(true);
        setuserIdToChangePassword(userId);
    };

    const [modalChangePasswordIsOpen, setModalChangePasswordIsOpen] = useState(false);
    const closeChangePasswordModal = () => {
        setModalChangePasswordIsOpen(false);
    }

    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
    const closeConfirmDeleteModal = () => {
        setModalDeleteIsOpen(false);
    }

    const [users, setUsers] = useState(null);

    const getUserFromGlobalParent = async (id) => {
        await axios.get(`${API_URL}/api/structure/all-users/${id}`, {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${contextByLocalStorage.token}`,
            },
        })
        .then((res) => {
            if(res.status === 200) {
                setUsers(res.data);    
            }
        })
        .catch((err) => console.log(err));
    };

    const getUsersIfUserIsAdmin = async () => {
        await axios.get(`${API_URL}/api/structure/structure/by-leader/${contextByLocalStorage.id}`, {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${contextByLocalStorage.token}`,
            },
        })
        .then((res) => {
            if(res.status === 200) {
                localStorage.setItem('context', JSON.stringify({
                    ...contextByLocalStorage,
                    team: res.data.id
                }));
            getUserFromGlobalParent(res.data.id);
            }
        })
        .catch((err) => console.log(err))
    };


    const getChildsFromLeader = async() => {
        await axios.post(`${API_URL}/api/structure/childs-from-leader`, {
            leaderId: contextByLocalStorage.id,
            "startDate": "2023-10-01",
            "endDate": "2023-10-20"
        }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${contextByLocalStorage.token}`
            },
        })
        .then((res) => {
            if(res.status === 200) {
                localStorage.setItem('context', JSON.stringify({
                    ...contextByLocalStorage,
                    childsFromLeader: res.data
                }));
                setUsers(res.data);
            }
        })
        .finally(() => {})
    };

    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [userIdToChangePassword, setuserIdToChangePassword] = useState(null);
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
        if(contextByLocalStorage.role === 'ADMIN') {
            getUsersIfUserIsAdmin();
        } else if (contextByLocalStorage.role === 'LEADER') {
            getChildsFromLeader();
        }
    }, []);
    
    const handleDeleteUser = async () => {
        axios.delete(`${API_URL}/user/delete/${userIdToDelete}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${contextByLocalStorage.token}`
            },
        })
        .then((res) => {
            if(res.status === 200) {
                closeConfirmDeleteModal();
                if(contextByLocalStorage.role === 'ADMIN') {
                    getUsersIfUserIsAdmin();
                } else if (contextByLocalStorage.role === 'LEADER') {
                    getChildsFromLeader();
                }
            }
        })
        .catch((err) => console.log(err));
    };

    const [showSuccessLabel, setShowSuccessLabel] = useState(false);
    const handleChangePassword = async () => {
        await axios.put(`${API_URL}/user/change-password`, {
            userId: userIdToChangePassword,
            newPassword
        }, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br'
            },
        })
        .then((res) => {
            if(res.status === 200) {
                setShowSuccessLabel(true);
                setTimeout(() => {
                    closeChangePasswordModal();
                    setShowSuccessLabel(false);
                    setNewPassword('');
                }, 1000);
            }
        })
        .catch((err) => console.log(err))
    };

    const [filtro, setFiltro] = useState('');
    const [resultadosFiltrados, setResultadosFiltrados] = useState(users);

    const manejarCambioInput = (event) => {
        const valorInput = event.target.value;
        setFiltro(valorInput);

        const resultados = users.filter(item => item.username.toLowerCase().includes(valorInput.toLowerCase()));
        setResultadosFiltrados(resultados);
      };

  return (
    <div className='padre'>
        <SideBar/>
        <div>
            <div className='header-container-delete'>
                <h1 className='delete-user-title'>Eliminar Usuario</h1>
                <span className='delete-user-subtitle'>Aquí están los detalles de eliminación de usuario.</span>
            </div>

            <div className="div3leaderboard delete-user-admin"> 
                        <div className="Recuadrinho1 table-delete-container">
                            <div className="titu35">
                                <div className="totalsales">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                        <circle cx="25" cy="25" r="25" fill="#2A2A2A"/>
                                        <image href={userIcon} x="13" y="12" width="24" height="24" clip-path="url(#circleClip)"/><defs/>
                                    </svg>
                                    <h1 className="h1titu3">Usuarios</h1>
                                </div>
                                <div className="partederecha2" style={{display: 'flex'}}>
                                    <div className='input-container'>
                                        <img src={searchIcon} alt='Icono de búsqueda' className='search-icon' />
                                        {users && <input onChange={manejarCambioInput} className='input-search-leaderboard' type="text" placeholder='Buscar vendedor' />}
                                    </div>
                                </div>
                            </div>
                            <table className="tabla">
                                {
                                    (users) > 0 && (
                                        <tr>
                                            <th className="Titulo-nombre-activity">Nombre</th>
                                            <th className="Titulo-ventas-activity">Rol</th>
                                            <th className="Titulo-ventaspendientes-activity"></th>
                                            <th className="Titulo-total-activity"></th>
                                        </tr>
                                    )
                                }
                                    <tbody>
                                    {
                                        (users && filtro === '') && users.filter(user => user.id !== contextByLocalStorage.id).map((user) => (
                                            <tr key={user.id}>
                                            <td className="tabla-nombrefoto">
                                                <img src={AvatarIcon4} alt=""/>
                                                <span className="tabla-Nombre">{user.username}</span>
                                            </td>
                                            <td className="tabla-totalventas">{user.role}</td>
                                            <td className="tabla-ventaspendientes">
                                                <div>
                                                    <button onClick={() => openChangePasswordModal(user.id)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} className='change-password-btn-table'>
                                                        <img src={lockWhiteIcon} alt="" style={{marginRight: 5}}/>
                                                        Cambiar contraseña</button>
                                                        <Modal
                                                            isOpen={modalChangePasswordIsOpen}
                                                            onRequestClose={closeChangePasswordModal}
                                                            contentLabel="Ascender a líder"
                                                            className="modal-content-profile change-content-modal"
                                                            shouldCloseOnOverlayClick={false}
                                                            overlayClassName="modal-overlay"
                                                            style={{
                                                                overlay: {
                                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                position: 'fixed',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                zIndex: 1000,
                                                                },
                                                                content: {
                                                                position: 'absolute',
                                                                backgroundColor: '#1E1E1E',
                                                                width: '800px',
                                                                height: '260px',
                                                                borderRadius: 16,
                                                                },
                                                            }}
                                                            >
                                                    {
                                                        showSuccessLabel && (
                                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                                                <p className='success-label-change'>Contraseña actualizada con éxito.</p>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        !showSuccessLabel && (
                                                            <>
                                                                <div className='modal-header-container'>
                                                                    <h1 className='ascend-title title-change-profile'>Nueva contraseña</h1>
                                                                    <button className='modal-close' onClick={closeChangePasswordModal}>Cerrar</button>
                                                                </div>
                                                                <div>
                                                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                                                        <input value={newPassword} className='change-password-input' type="password" 
                                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className='change-password-button-container'>
                                                                        <button className='change-password-button' onClick={handleChangePassword}>Cambiar Contraseña</button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    </Modal>
                                                </div>
                                            </td>     
                                            <td className="tabla-total">
                                            <div>
                                                <button onClick={() => openConfirmDeleteUserModal(user.id)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} className='delete-btn-table'>
                                                    <img src={crossWhite} alt="" style={{marginRight: 5}}/>
                                                    Eliminar</button>
                                                    <Modal
                                                    isOpen={modalDeleteIsOpen}
                                                    onRequestClose={closeConfirmDeleteModal}
                                                    contentLabel="Ascender a líder"
                                                    className="modal-content-profile delete-content-modal"
                                                    shouldCloseOnOverlayClick={false}
                                                    overlayClassName="modal-overlay"
                                                    style={{
                                                        overlay: {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                        position: 'fixed',
                                                        top: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        zIndex: 1000,
                                                        },
                                                        content: {
                                                        position: 'absolute',
                                                        backgroundColor: '#1E1E1E',
                                                        width: '800px',
                                                        height: '200px',
                                                        borderRadius: 16,
                                                        },
                                                    }}
                                                    >
                                                    <div className='modal-header-container'>
                                                        <h1 className='ascend-title title-change-profile'>¿Eliminar este usuario?</h1>
                                                        <button className='modal-close' onClick={closeConfirmDeleteModal}>Cerrar</button>
                                                    </div>
                                                    <div>
                                                        <div className='change-password-button-container'>
                                                            <button className='change-password-button' onClick={() => handleDeleteUser()}>Si, Eliminar.</button>
                                                        </div>
                                                    </div>
                                                    </Modal>
                                            </div>
                                            </td>                      
                                        </tr>
                                        ))
                                    }
                                                                        {
                                        (resultadosFiltrados && filtro !== '') && resultadosFiltrados.filter(user => user.id !== contextByLocalStorage.id).map((user) => (
                                            <tr key={user.id}>
                                            <td className="tabla-nombrefoto">
                                                <img src={AvatarIcon4} alt=""/>
                                                <span className="tabla-Nombre">{user.username}</span>
                                            </td>
                                            <td className="tabla-totalventas">{user.role}</td>
                                            <td className="tabla-ventaspendientes">
                                                <div>
                                                    <button onClick={() => openChangePasswordModal(user.id)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} className='change-password-btn-table'>
                                                        <img src={lockWhiteIcon} alt="" style={{marginRight: 5}}/>
                                                        Cambiar contraseña</button>
                                                        <Modal
                                                            isOpen={modalChangePasswordIsOpen}
                                                            onRequestClose={closeChangePasswordModal}
                                                            contentLabel="Ascender a líder"
                                                            className="modal-content-profile change-content-modal"
                                                            shouldCloseOnOverlayClick={false}
                                                            overlayClassName="modal-overlay"
                                                            style={{
                                                                overlay: {
                                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                position: 'fixed',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                zIndex: 1000,
                                                                },
                                                                content: {
                                                                position: 'absolute',
                                                                backgroundColor: '#1E1E1E',
                                                                width: '800px',
                                                                height: '260px',
                                                                borderRadius: 16,
                                                                },
                                                            }}
                                                            >
                                                    {
                                                        showSuccessLabel && (
                                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                                                <p className='success-label-change'>Contraseña actualizada con éxito.</p>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        !showSuccessLabel && (
                                                            <>
                                                                <div className='modal-header-container'>
                                                                    <h1 className='ascend-title title-change-profile'>Nueva contraseña</h1>
                                                                    <button className='modal-close' onClick={closeChangePasswordModal}>Cerrar</button>
                                                                </div>
                                                                <div>
                                                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                                                        <input value={newPassword} className='change-password-input' type="password" 
                                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className='change-password-button-container'>
                                                                        <button className='change-password-button' onClick={handleChangePassword}>Cambiar Contraseña</button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    </Modal>
                                                </div>
                                            </td>     
                                            <td className="tabla-total">
                                            <div>
                                                <button onClick={() => openConfirmDeleteUserModal(user.id)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} className='delete-btn-table'>
                                                    <img src={crossWhite} alt="" style={{marginRight: 5}}/>
                                                    Eliminar</button>
                                                    <Modal
                                                    isOpen={modalDeleteIsOpen}
                                                    onRequestClose={closeConfirmDeleteModal}
                                                    contentLabel="Ascender a líder"
                                                    className="modal-content-profile delete-content-modal"
                                                    shouldCloseOnOverlayClick={false}
                                                    overlayClassName="modal-overlay"
                                                    style={{
                                                        overlay: {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                        position: 'fixed',
                                                        top: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        zIndex: 1000,
                                                        },
                                                        content: {
                                                        position: 'absolute',
                                                        backgroundColor: '#1E1E1E',
                                                        width: '800px',
                                                        height: '200px',
                                                        borderRadius: 16,
                                                        },
                                                    }}
                                                    >
                                                    <div className='modal-header-container'>
                                                        <h1 className='ascend-title title-change-profile'>¿Eliminar este usuario?</h1>
                                                        <button className='modal-close' onClick={closeConfirmDeleteModal}>Cerrar</button>
                                                    </div>
                                                    <div>
                                                        <div className='change-password-button-container'>
                                                            <button className='change-password-button' onClick={() => handleDeleteUser()}>Si, Eliminar.</button>
                                                        </div>
                                                    </div>
                                                    </Modal>
                                            </div>
                                            </td>                      
                                        </tr>
                                        ))
                                    }                                                                                     
                                        {
                                            (users && users.length === 0) && (
                                                <div className='not-result-leaderboard-container' style={{height: 200, display: 'flex', justifyContent: 'center', width: '1080px', alignItems: 'center'}}>
                                                    <span className='no-results-label'>No hay resultados que mostrar.</span>
                                                </div>
                                            )
                                        }
                                    </tbody>
                            </table>
                            
                        </div>
                    </div>
        </div>
    </div>
  )
}
