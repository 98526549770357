import React, { useEffect, useState } from 'react'
import SideBar from '../home/SideBar.jsx';
import axios from 'axios';
import {API_URL} from '../../constants.ts';
import '../../css/paymentMethods/paymentMethods.css'
import wu from '../../assets/images/wu.svg';
import zelle from '../../assets/images/zelle.svg';
import apple from '../../assets/images/apple_pay.svg';
import cash_app from '../../assets/images/cash_app.svg';
import paylink from '../../assets/images/payment_link.svg';
import bankTransfer from '../../assets/images/bank_transfer.svg';
import crypto from '../../assets/images/bitcoin.svg';
import revolut from '../../assets/images/revolut.svg';
import wise from '../../assets/images/wise.png';

export const PaymentMethods = () => {
  const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
  const paymentMethods = [
    'ZELLE',
    'APPLE_PAY',
    'CASH_APP',
    'PAYLINK',
    'BANK_TRANSFER',
    'WISE',
    'WESTERN_UNION',
    'CRYPTO',
    'REVOLUT'
  ];

  const imagesPaymentMethods = {
    'ZELLE': zelle,
    'APPLE_PAY': apple,
    'CASH_APP': cash_app,
    'PAYLINK': paylink,
    'BANK_TRANSFER': bankTransfer,
    'WISE': wise,
    'WESTERN_UNION': wu,
    'CRYPTO': crypto,
    'REVOLUT': revolut
  }

  const getImageFromPM = (pm) => {
    return imagesPaymentMethods[pm] || bankTransfer;
  }

  const [apiResponses, setApiResponses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const responses = [];

      for (const paymentMethod of paymentMethods) {
        try {
          const response = await axios.post(`${API_URL}/sales-info/get-info`, {
            "paymentMethod": paymentMethod,
            "start": "2023-10-01T00:00:00Z",
            "finish": "2023-10-31T00:00:00Z"
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${contextByLocalStorage.token}`,
              'Accept-Encoding': 'gzip, deflate, br'
            },
          });

          responses.push(response.data); // Puedes ajustar esto según la estructura de tu respuesta
        } catch (error) {
          console.error(`Error fetching data for ${paymentMethod}:`, error);
        }
      }

      setApiResponses(responses);
    };

    fetchData();
  }, [])

  return (
    <div className='padre'>
      <SideBar/>
      <div>
          <div style={{margin: 40}}>
              <h1 className='title-header-pm'>Métodos de Pago</h1>
              <p className='subtitle-header-pm'>Aquí están los detalles de sus métodos de pago</p>
          </div>

        <div className='box-container'>
        {
          apiResponses.length > 0 && apiResponses.map((pm) => (
            <div className='pm-box'>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin:20}}>
                <img className='pm-img' alt='western union image' src={getImageFromPM(pm.paymentMethod)} />
                <span className='pm-label'>{pm.paymentMethod}</span>
              </div>
              <div style={{marginTop: 40, marginLeft: 10}}>
              <span className='pm-total'>${pm.ammount}</span>
              </div>
            </div>
          ))
        }
        </div>
      </div>
    </div>
  )
}
