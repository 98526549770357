import React from 'react';
import SideBar from '../components/home/SideBar';
import Dashboard from '../components/home/Dashboard';
// import Leaderboard from '../components/home/Leaderboard';
import MyTeam from '../components/home/HeaderMyTeam';
// import Analitycs from '../components/home/Analitycs';
import { Routes, Route } from "react-router-dom";

import "../css/home/home.css";

const Home = () => {
  return (
    <div className="padre">
        <SideBar />
        <Dashboard />     
    </div> 
  );
}
export default Home;