import React, { useEffect, useState } from 'react'
import SideBar from '../home/SideBar';
import Modal from 'react-modal';
import menuIcon from '../../assets/icon/gold-menu.svg';
import clockIcon from '../../assets/icon/TimeCircle2.svg';
import axios from 'axios';
import { API_URL } from '../../constants.ts';

Modal.setAppElement('#root');

export const HomeCampaign = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
    const [newCampaigns, setNewCampaigns] = useState([]);
    const [oldCampaigns, setOldCampaigns] = useState([]);

    const getNewCampaigns = async () => {
        await axios.get(API_URL + `/campaign/get-campaigns/false`, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                "ngrok-skip-browser-warning":"any"
              }
        })
        .then((res) => {
            if(res.status === 200) {
                setNewCampaigns(res.data);
            }
        })
        .catch((err) => console.log(err))
    };

    const getOldCampaigns = async () => {
        await axios.get(API_URL + `/campaign/get-campaigns/true`, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                "ngrok-skip-browser-warning":"any"
              }
        })
        .then((res) => {
            if(res.status === 200) {
                setOldCampaigns(res.data);
            }
        })
        .catch((err) => console.log(err))
    };

    useEffect(() => {
      getNewCampaigns();
      getOldCampaigns();
    }, []);    

    return (
    <div className='padre'>
        <SideBar/>
        <div className='validate-sales-container'>
            <div className='validate-sales-header-container'>
                <h1 className='sales-title'>Bienvenido, {contextByLocalStorage.username}</h1>
                <p className='sales-subtitle'>Aquí tienes el número de campañas que se deben realizar:</p>
            </div>

            <div className='sales-box-container'>
                    <div className='sales-to-validate-box'>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', margin: 10}}>
                            <img className='clock-img' alt='icon time image' src={menuIcon} />
                            <p className='validate-sales-box-subtitle'>Nuevas campañas</p>
                        </div>
                        <div style={{margin: 20}}>
                            <p className='total-sales-number'>{newCampaigns.length}</p>
                        </div>
                    </div>
                    <div className='sales-validated-box'>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', margin: 10}}>
                            <img className='clock-img' alt='icon time image' src={clockIcon} />
                            <p className='validate-sales-box-subtitle'>Campañas antiguas</p>
                        </div>
                        <div style={{margin: 20}}>
                            <p className='total-sales-number'>{oldCampaigns.length}</p>
                        </div>
                    </div>
                </div>
        </div>
    </div>
  )
}
