import React, { useEffect, useState } from 'react'
import SideBar from '../home/SideBar'
import '../../css/sales/sales.css';
import iconFile from '../../assets/icon/file.svg';
import axios from 'axios';
import { API_URL } from '../../constants.ts'
import { Loading } from '../Loading/Loading.jsx';

export const UploadSales = () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
    const [formData, setFormData] = useState({
        link: '',
        pack: '',
        platform: 'INSTAGRAM',
        price: null,
        location: '',
        paymentMethod: 'APPLE_PAY'
    })
    const [ticket, setTicket] = useState(null);

    const [links, setLinks] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [showSuccessLabel, setShowSuccessLabel] = useState(false);
    const [showErrorLabel, setShowErrorLabel] = useState(false);

    const handleCreateSale = async () => {
        setShowErrorLabel(false);
        if(links.length === 0 || formData.pack.length === 0 || formData.price === null || formData.location.length === 0 || ticket === null) {
            setShowErrorLabel(true);
            return;
        }
        setIsLoading(true);
        const formUploadData = new FormData();
        formUploadData.append('file', ticket);
        const saleData = {
            clientName: contextByLocalStorage.username,
            seller: {
                id: contextByLocalStorage.id
            },
            date: new Date().toISOString(),
            pack: formData.pack,
            location: formData.location,
            paymentMethod: formData.paymentMethod,
            price: formData.price,
            platform: formData.platform,
            links
        };
      
        formUploadData.append('sale', new Blob([JSON.stringify(saleData)], { type: 'application/json' }));
          try {
            await fetch(API_URL + '/sale/create', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
              },
              body: formUploadData,
            })
            .then((res) => {
                if(res.status === 200) {
                    setFormData({
                        link: '',
                        pack: '',
                        platform: 'INSTAGRAM',
                        price: 0,
                        location: '',
                        paymentMethod: 'APPLE_PAY'
                    });
                    setTicket(null);
                    setLinks([]);
                    setShowSuccessLabel(true);
                    setTimeout(() => {
                        setShowSuccessLabel(false);
                    }, 3000);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
        }catch(err) {
            console.log(err)
        }
    };

  return (
    <div className='padre'>
        <SideBar/>
        <div className='sales-container'>
            {isLoading && <Loading/>}
            <div className='sales-form'>
                <div style={{marginTop: 40, marginBottom: 40}}>
                    <h1 className='sales-title'>Cargar ventas</h1>
                </div>
                <div className='input-div-sales'>
                    <p>Link(s)</p>
                    <div className='links-input-container'>
                        <input value={formData.link} onChange={(e) => setFormData((prev) => ({
                            ...prev,
                            link: e.target.value
                        }))} placeholder='Agregá tu enlace' className='links-input' type="text" />
                        <button className="custom-button" onClick={() => {
                            if(formData.link.length > 0) {
                                const linksArray = [...links];
                                linksArray.push(formData.link);
                                setLinks(linksArray);
                                setFormData((prev) => ({
                                    ...prev,
                                    link: ''
                                }))
                            }
                        }}>+</button>
                    </div>
                    {
                        links && links.map((link) => <li key={link} className='link-list-li'>
                        <span className='link-list-label'>{link}</span>
                        <button className='remove-link-btn' onClick={() => {
                            const prevArrayLinks = [...links];
                            let elementToDelete = prevArrayLinks.indexOf(link);
                            if (elementToDelete !== -1) {
                                prevArrayLinks.splice(elementToDelete, 1);
                            }
                            setLinks(prevArrayLinks);
                        }}>x</button>
                    </li>)
                    }
                </div>
                <div className='input-div-sales'>
                    <p>Paquete</p>
                    <input value={formData.pack} placeholder='Tu paquete' type="text" onChange={(e) => setFormData((prev) => ({
                ...prev,
                pack: e.target.value
              }))} />
                </div>
                <div className='input-div-sales'>
                    <p>Plataforma</p>
                    <select className='select-platform' onChange={(e) => setFormData((prev) => ({
                        ...prev,
                        platform: e.target.value
                    }))}>
                        <option defaultValue="INSTAGRAM" selected>Instagram</option>
                        <option value="TWITTER">Twitter</option>
                        <option value="FACEBOOK">Facebook</option>
                        <option value="WHATSAPP">Whatsapp</option>
                    </select>
                </div>
                <div className='input-div-sales'>
                    <p>Precio</p>
                    <input value={formData.price} min='0' onChange={(e) => setFormData((prev) => ({
                        ...prev,
                        price: e.target.value
                    }))} 
                    placeholder='Dale un precio a tu servicio' type="number" />
                </div>
                <div className='input-div-sales'>
                    <p>Ubicación</p>
                    <input onChange={(e) => setFormData((prev) => ({
                        ...prev,
                        location: e.target.value
                    }))} placeholder='Tu ubicación' value={formData.location} type="text" />
                </div>
                <div className='input-div-sales'>
                    <p>Métodos de pago</p>
                    <select className='select-payment-method' onChange={(e) => setFormData((prev) => ({
                        ...prev,
                        paymentMethod: e.target.value
                    }))}>
                        <option defaultValue="APPLE_PAY" selected>Apple Pay</option>
                        <option value="ZELLE">Zelle</option>
                        <option value="CASH_APP">Cash App</option>
                        <option value="PAYLINK">Paylink</option>
                        <option value="BANK_TRANSFER">Transferencia bancaria</option>
                        <option value="WISE">Wise</option>
                        <option value="WESTERN_UNION">Western Union</option>
                        <option value="CRYPTO">Crypto</option>
                        <option value="REVOLUT">Revolut</option>
                    </select>
                </div>
                <div className='input-div-sales' style={{width: '90%'}}>
                    <p>Comprobante</p>
                    {
                        !ticket ? <div class="custom-file-input">
                        <input onChange={(e) => setTicket(e.target.files[0])} type="file" accept=".jpg, .png, .svg" id="fileInput" />
                        <label for="fileInput">
                            <img alt='icon file image' src={iconFile} />
                            <span>Elegir archivo</span>
                        </label>
                    </div>
                    :
                    <div style={{ textAlign: 'center' }}>
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                <img
                                src={URL.createObjectURL(ticket)}
                                alt="Imagen seleccionada"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '300px',
                                    display: 'block',
                                    margin: 'auto',
                                }}
                                />
                                <label htmlFor="cambiarArchivo" style={{
                                    position: 'absolute',
                                    bottom: '10px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    background: '#2E2E2E',
                                    color: '#fff',
                                    padding: '8px',
                                    cursor: 'pointer',
                                    borderRadius: '5px',
                                    display: 'block',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                }}>
                                Cambiar archivo
                                <input
                                    type="file"
                                    accept=".jpg, .png, .svg"
                                    id="cambiarArchivo"
                                    onChange={(e) => setTicket(e.target.files[0])}
                                    style={{display: 'none',}}
                                />
                                </label>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    {!showSuccessLabel && <button disabled={isLoading} onClick={handleCreateSale} className='btn-continue-sales'>Continuar</button>}
                </div>
                <div>
                    {showSuccessLabel && <p className='success-label s'>Venta cargada con éxito</p>}
                    {
                        showErrorLabel && <p className='error-label e'>Completar los campos obligatorios.</p>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
