import React, { useEffect, useState } from 'react';
import "../../../src/css/home/home.css";
import HeaderLeaderboard from './HeaderLeaderboard';
import GrupoIcon from "../../../src/assets/icon/Iconly (1).svg";
import calendarIcon from "../../../src/assets/icon/Calendar.svg";
import searchIcon from "../../../src/assets/icon/search.svg";
import AvatarIcon4 from "../../../src/assets/icon/Ellipse 213.svg";
import defaultUser from "../../../src/assets/images/default-user.png";
import '../../css/leaderboard/leaderboard.css';
import axios from 'axios';
import { API_URL } from '../../constants.ts';

const Leaderboard= () => {
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
    const [scores, setScores] = useState([]);

    const getTeamByLeaderId = async () => {
        await axios.get(`${API_URL}/api/structure/structure/by-leader/${contextByLocalStorage.id}`, {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${contextByLocalStorage.token}`,
            },
        })
        .then((res) => {
            if(res.status === 200) {
                localStorage.setItem('context', JSON.stringify({
                    ...contextByLocalStorage,
                    team: res.data.id
                }));
                getAllUserScoresByStructure()
            }
        })
        .catch((err) => console.log(err))
    }

    const getAllUserScoresByStructure = async (filterDate = formattedOneWeekBefore) => {
        await axios.post(API_URL + `/leaderbord/users/scores/by-structure`,{
            structureId: contextByLocalStorage.team,
            startDate: filterDate,
            endDate: formattedCurrentDate
        } ,{
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                "ngrok-skip-browser-warning":"any"
              }
        })
        .then((res) => {
            if(res.status === 200) {
                setChilds(res.data);
                console.log(res.data)
            }
        })
        .catch((err) => console.log(err))
    };

    const currentDate = new Date();
    const oneWeekBefore = new Date(currentDate);
    const oneMonthBefore = new Date(currentDate);
    const oneYearBefore = new Date(currentDate);
    oneYearBefore.setFullYear(currentDate.getFullYear() - 1);
    oneWeekBefore.setDate(currentDate.getDate() - 7);
    oneMonthBefore.setMonth(currentDate.getMonth() - 1);;
    
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];
    const formattedOneWeekBefore = oneWeekBefore.toISOString().split('T')[0];
    const formattedOneMonthBefore = oneMonthBefore.toISOString().split('T')[0];
    const formattedOneYearBefore = oneYearBefore.toISOString().split('T')[0];

    const [childs, setChilds] = useState([]);



    useEffect(() => {
      if(!contextByLocalStorage.team) {
        getTeamByLeaderId();
      }else {
        getAllUserScoresByStructure();
      }
    }, []);

    
    const [filtro, setFiltro] = useState('');
    const [resultadosFiltrados, setResultadosFiltrados] = useState(childs);

    const manejarCambioInput = (event) => {
        const valorInput = event.target.value;
        setFiltro(valorInput);
    
        const resultados = childs.filter(item => item.seller.username.toLowerCase().includes(valorInput.toLowerCase()));
        setResultadosFiltrados(resultados);
      };

    return(
        <div className="analytics">
            <div className="parentLeaderboard">
                <HeaderLeaderboard/>
                <div className="div2leaderboard">
                        <div className="hijos">
                            <div className="divuno"> 
                                <div className="rect1">
                                    <img src={defaultUser} style={{background: 'white', padding: 10}} alt=""/>
                                    <div className="rectarriba">
                                        <h1 className="posicion">#2</h1>
                                    </div>
                                </div>

                            </div>
                            <div className="divdos"> 
                                <div className="rect2">
                                    <img src={defaultUser} style={{background: 'white', padding: 10}} alt=""/>
                                    <div className="rectarriba">
                                        <h1 className="posicion">#1</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="divtres"> 
                                <div className="rect3">
                                    <img src={defaultUser} style={{background: 'white', padding: 10}} alt=""/>
                                    <div className="rectarriba">
                                        <h1 className="posicion">#3</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="div3leaderboard"> 
                        <div className="Recuadrinho1">
                            <div className="titu35">
                                <div className="totalsales">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                        <circle cx="25" cy="25" r="25" fill="#2A2A2A"/>
                                        <image href={GrupoIcon} x="13" y="12" width="24" height="24" clip-path="url(#circleClip)"/><defs/>
                                    </svg>
                                    <h1 className="h1titu3">Lista de Posiciones</h1>
                                </div>
                                <div className="partederecha2" style={{display: 'flex'}}>
                                    <div className='input-container'>
                                        <img src={searchIcon} alt='Icono de búsqueda' className='search-icon' />
                                        <input onChange={manejarCambioInput} className='input-search-leaderboard' type="text" placeholder='Buscar vendedores' />
                                    </div>
                                    <div className="select-con-imagen" style={{marginBottom: 20}}>
                                        <span className="imagen-select">
                                            <img src={calendarIcon} alt="Imagen de select" />
                                        </span>
                                        <select onChange={(e) => getAllUserScoresByStructure(e.target.value)}>
                                            <option value={formattedOneWeekBefore}>Weekly</option>
                                            <option value={formattedOneMonthBefore}>Monthly</option>
                                            <option value={formattedOneYearBefore}>Year</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <table className="tabla">
                                    {
                                        (childs.length > 0 && filtro === '') && (
                                            <tr>
                                                <th className="Titulo-nombre-activity">Nombre Vendedor</th>
                                                <th className="Titulo-ventas-activity">Ventas Totales</th>
                                                <th className="Titulo-ventaspendientes-activity">Ventas Pendientes</th>
                                                <th className="Titulo-total-activity">ganancia Total</th>
                                            </tr>
                                        )
                                    }
                                    {
                                        (resultadosFiltrados.length > 0 && filtro !== '') && (
                                            <tr>
                                                <th className="Titulo-nombre-activity">Nombre Vendedor</th>
                                                <th className="Titulo-ventas-activity">Ventas Totales</th>
                                                <th className="Titulo-ventaspendientes-activity">Ventas Pendientes</th>
                                                <th className="Titulo-total-activity">ganancia Total</th>
                                            </tr>
                                        )
                                    }
                                    <tbody>
                                        {
                                            (childs.length > 0 && filtro === '') > 0 && childs.map((child) => (
                                                <tr>
                                                    <td className="tabla-nombrefoto">
                                                        <img src={AvatarIcon4} alt=""/>
                                                        <span className="tabla-Nombre">{child.seller.username}</span>
                                                    </td>
                                                    <td className="tabla-totalventas">${child.totalSales}</td>
                                                    <td className="tabla-ventaspendientes">${child.pendingSales}</td>
                                                    <td className="tabla-total">${child.totalProfit}</td>                      
                                                </tr>
                                            ))
                                        }
                                        {
                                            (resultadosFiltrados.length && filtro !== '') > 0 && resultadosFiltrados.map((child) => (
                                                <tr>
                                                    <td className="tabla-nombrefoto">
                                                        <img src={AvatarIcon4} alt=""/>
                                                        <span className="tabla-Nombre">{child.seller.username}</span>
                                                    </td>
                                                    <td className="tabla-totalventas">${child.totalSales}</td>
                                                    <td className="tabla-ventaspendientes">${child.pendingSales}</td>
                                                    <td className="tabla-total">${child.totalProfit}</td>                      
                                                </tr>
                                            ))
                                        }
                                        {
                                            (childs.length === 0 || (resultadosFiltrados.length === 0 && filtro !== '')) && (
                                                <div className='not-result-leaderboard-container' style={{height: 200, display: 'flex', justifyContent: 'center', width: '1080px', alignItems: 'center'}}>
                                                    <span className='no-results-label'>No hay resultados que mostrar.</span>
                                                </div>
                                            )
                                        }
                                    </tbody>
                            </table>
                            
                        </div>
                    </div>
                    </div>
        </div>
    )
}
export default Leaderboard;

