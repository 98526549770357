import React from 'react'
import SidePhoto from "../../../src/assets/images/Frame 585.jpg";
import "../../../src/css/login/side-picture.css";

const SidePicture = () => {
  return (
    <div className="imagen-login-div"> 
        <img src={SidePhoto} className="img-login" alt="" /> 
    </div>
  );
};
export default SidePicture;