import LoginCard  from '../components/login/LoginCard';
import SidePicture from '../components/login/SidePicture';

const Login = () => {
    return (
    <div className="general">
        <SidePicture />
        <LoginCard />
    </div>
    );
}
export default Login;