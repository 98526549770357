import React from 'react';
import SideBar from '../components/home/SideBar';
// import Dashboard from '../components/home/Dashboard';
// import Leaderboard from '../components/home/Leaderboard';
// import MyTeam from '../components/home/MyTeam';
import "../css/home/home.css";
import MyTeam from '../components/home/MyTeam';

const HomeMyTeam = () => {
  return (
    <div className="padre">
        <SideBar />
        <MyTeam />  
            
    </div> 
  );
}
export default HomeMyTeam;