import axios from 'axios';
import React, { useContext, useState } from 'react'
import Modal from 'react-modal';
import { API_URL } from '../../constants.ts';
import { Context } from '../../App';

Modal.setAppElement('#root');

export const AscendAsLeaderResp = (data) => {
    const {context, setContext } = useContext(Context);
    const contextByLocalStorage = JSON.parse(localStorage.getItem('context'));
    const openConfirmModal = () => {
        setModalIsOpen(true);
    };
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const closeConfirmModal = () => {
        setModalIsOpen(false);
    };

    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async() => {
        setIsLoading(true);
        await axios.put(`${API_URL}/user/assign-sub-leader/${contextByLocalStorage.id}/${data.data.user.id}`,null, {
            headers: {
                'Authorization': `Bearer ${contextByLocalStorage.token}`,
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br'
            },
          })
          .then((res) => {
            if(res.status === 200) {
                closeConfirmModal();
                setContext({
                    ...context,
                    reloadMyTeam: true
                })
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setIsLoading(false))
    };

  return (
    <div>
        <button onClick={openConfirmModal} className='employee-case-resp'>Ascender a lider</button>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeConfirmModal}
            contentLabel="Ascender a lider"
            className="modal-content"
            shouldCloseOnOverlayClick={false}
            overlayClassName="modal-overlay"
            style={{
                overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                },
                content: {
                position: 'absolute',
                top: '100%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#1E1E1E',
                width: '400px',
                height: '250px',
                margin: 'auto',
                zIndex: 1001,
                borderRadius: 16
                },
            }}
        >
            <h1 className='ascend-title'>Ascender a lider?</h1>
            <div className='btn-confirm-container'>
                <button onClick={handleConfirm} className='btn-ascend-confirm'>Confirmar</button>
                <button onClick={closeConfirmModal} className='btn-ascend-cancel'>Cancelar</button>
            </div>
        </Modal>
    </div>
  )
}
