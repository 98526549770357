import React from 'react';
import '../../css/home/myTeam.css';
import AvatarIcon3 from "../../../src/assets/images/Rectangle 14.png";
import { AscendAsLeader } from '../modals/AscendAsLeader';
import { AscendAsLeaderResp } from '../modals/AscendAsLeaderResp';

const Member = ({data}) => {
    const getRoleLabel = (role) => {
        const labelByRole = {
            EMPLOYEE: 'Vendedor',
            LEADER: 'Lider',
            VALIDATOR: 'Validador'
        }
        return labelByRole[role] || '-'
    }
    return (
        <div key={data.node.user.id} className='member-container'>
        <div className="member-wrapper">
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <img className="fotoperfil" src={AvatarIcon3} alt=""/>
                    <h1 style={{ marginLeft: 15}}>{data.node.user.username}</h1>
                </div>
    
                <div style={{ marginLeft: 30, marginRight: 30}}>
                    <span className='role-label'>{getRoleLabel(data.node.user.role)}</span>
                </div>
                <div>
                    <span className='revenue-label'>{data.node.percentage}%</span>
                </div>
                <div>
                    {getRoleLabel(data.node.user.role) === 'Vendedor' 
                    ? <AscendAsLeader data={data.node}/>
                    : <button className='leader-case' disabled>Lider</button>
                }
                </div>
            </div>
                <div>
            </div>
        </div>
        <div>
        {getRoleLabel(data.node.user.role) === 'Vendedor' 
                    ? <AscendAsLeaderResp data={data.node}/>
                    : <button className='leader-case-resp' disabled>Lider</button>
                }
        </div>
        <div className="devider2">
            <hr></hr>
        </div>
    </div>
    );
};

export default Member;